import React, {useEffect} from 'react';
import {products} from "../../../data/productListForGrid";
import ProductListGrid from "../ProductListGrid";

const HobsPage = () => {

    const hobs = products.filter((product) => product.category === "hoods");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductListGrid
            title={"All Our Hoods"}
            products={hobs}
        />
    )
};

export default HobsPage;
