import {KitchenMixer} from "../interfaces";

export const NewKitchenMixersList: KitchenMixer[] = [
    {
        id: 35,
        name: "FAF160 CR",
        color: "Chrome mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 CR_1.webp")
    },
    {
        id: 36,
        name: "FAF160 B",
        color: "Black mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 B_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 B_1.webp")
    },
    {
        id: 37,
        name: "FAF160 G",
        color: "Gray mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 G_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 G_1.webp")
    },
    {
        id: 38,
        name: "FAF160 A",
        color: "Grey-beige mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 A_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 A_1.webp")
    },
    {
        id: 39,
        name: "FAF160 AV",
        color: "Avena mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 AV_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 AV_1.webp")
    },
    {
        id: 40,
        name: "FAF160 W",
        color: "White mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 W_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 W_1.webp")
    },
    {
        id: 41,
        name: "FAF160 S",
        color: "Champagne mixer",
        design: "High and curved spout",
        description: "Facilitates the filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 S_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 S_1.webp")
    },
    {
        id: 42,
        name: "FAF110 CR",
        color: "Chrome-plated mixer with hand shower",
        design: "Long and ergonomic control level, a valve body with a striking design, retracted shower-head inside the aerator",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF160 S_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF160 S_1.webp")
    },
    {
        id: 43,
        name: "FAF110 B",
        color: "Black mixer with hand shower\n",
        design: "Long and ergonomic control level, a valve body with a striking design, retracted shower-head inside the aerator",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF110 B_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF110 B_1.webp")
    },
    {
        id: 44,
        name: "FAF110 G",
        color: "Grey mixer with hand shower\n",
        design: "Long and ergonomic control level, a valve body with a striking design, retracted shower-head inside the aerator",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF110 G_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF110 G_1.webp")
    },
    {
        id: 45,
        name: "FAF110 A",
        color: "Grey-beige mixer with hand shower",
        design: "Long and ergonomic control level, a valve body with a striking design, retracted shower-head inside the aerator",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF110 A_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF110 A_1.webp")
    },
    {
        id: 46,
        name: "FAF110 W",
        color: "White mixer with hand shower",
        design: "Long and ergonomic control level, a valve body with a striking design, retracted shower-head inside the aerator",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF110 W_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF110 W_1.webp")
    },
    {
        id: 47,
        name: "FAF103 S",
        color: "Champagne mixer with hand shower",
        design: "A valve body with a striking design, long and ergonomic control level",
        description: "High neck for easy filling of large containers",
        image: require("../../assets/NewKitchenMixers/FAF103 S_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF103 S_1.webp")
    },
    {
        id: 48,
        name: "FAF106 CR",
        color: "Chrome mixer",
        design: "Square and slender shape associated with the softness of the edges, suitable for sinks with a straight design and under mount tubs, 360 degrees swivel spout for a wider scope",
        description: "Slender spout for easy filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF106 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF106 CR_1.webp")
    },
    {
        id: 49,
        name: "FAF104",
        color: "Chrome mixer",
        design: "Non Disponibile",
        description: "Sink Mixer With Pull-out Hand Shower",
        image: require("../../assets/NewKitchenMixers/FAF104_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF104_1.webp")
    },
    {
        id: 50,
        name: "FAF111 CR",
        color: "Chrome mixer",
        design: "Minimalist design",
        description: "Particularly high spout for a better filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF111 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF111 CR_1.webp")
    },
    {
        id: 51,
        name: "FAF111 B",
        color: "Black mixer",
        design: "Minimalist design",
        description: "Particularly high spout for a better filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF111B_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF111 B_1.webp")
    },
    {
        id: 52,
        name: "FAF111 G",
        color: "Gray mixer",
        design: "Minimalist design",
        description: "Particularly high spout for a better filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF111 G_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF111 G_1.webp")
    },
    {
        id: 53,
        name: "FAF111 A",
        color: "Gray-Beige mixer",
        design: "Minimalist design",
        description: "Particularly high spout for a better filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF111 A_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF111 A_1.webp")
    },
    {
        id: 54,
        name: "FAF111 W",
        color: "White mixer",
        design: "Minimalist design",
        description: "Particularly high spout for a better filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF111 W_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF111 W_1.webp")
    },
    {
        id: 55,
        name: "FAF7045 CR",
        color: "3-way chrome mixer",
        design: "Practical 2in 1 mixer with hot/cold water control lever on the right and filtered water control lever on the left. Hygienic separated circuit with additional spout for filtered water. 360 degrees swivel spout for a wider scope.",
        description: "Space saving and user friendly: no need for a second circuit for filtered water",
        image: require("../../assets/NewKitchenMixers/FAF7045 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF7045 CR_1.webp")
    },
    {
        id: 56,
        name: "FAF7045 B",
        color: "3-way black mixer",
        design: "Practical 2in 1 mixer with hot/cold water control lever on the right and filtered water control lever on the left. Hygienic separated circuit with additional spout for filtered water. 360 degrees swivel spout for a wider scope.",
        description: "Space saving and user friendly: no need for a second circuit for filtered water",
        image: require("../../assets/NewKitchenMixers/FAF7045 B_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF7045 B_1.webp")
    },
    {
        id: 57,
        name: "FAF7045 A",
        color: "3-way gray-beige mixer",
        design: "Practical 2in 1 mixer with hot/cold water control lever on the right and filtered water control lever on the left. Hygienic separated circuit with additional spout for filtered water. 360 degrees swivel spout for a wider scope.",
        description: "Space saving and user friendly: no need for a second circuit for filtered water",
        image: require("../../assets/NewKitchenMixers/FAF7045 A_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF7045 A_1.webp")
    },
    {
        id: 58,
        name: "FAF105 CR",
        color: "Chrome mixer",
        design: "Square and slender shape associated with the softness of the edges, suitable for sinks with a straight design and under mount tubs. 360 degrees swivel spout for a wider scope",
        description: "Slender spout for easy filling of pots and pans",
        image: require("../../assets/NewKitchenMixers/FAF105 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF105 CR_1.webp")
    },
    {
        id: 59,
        name: "FAF123",
        color: "Chrome mixer",
        design: "Non Disponibile",
        description: "Sink mixer with swivel spout",
        image: require("../../assets/NewKitchenMixers/FAF123_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF123_1.webp")
    },
    {
        id: 60,
        name: "FAF133",
        color: "Chrome mixer",
        design: "Non Disponibile",
        description: "Sink mixer with swivel spout",
        image: require("../../assets/NewKitchenMixers/FAF133_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF133_1.webp")
    },
    {
        id: 61,
        name: "FAF109 CR",
        color: "Chrome mixer",
        design: "Thin mixer with aesthetic design, with pull-out shower",
        description: "Particularly suitable for compact sinks",
        image: require("../../assets/NewKitchenMixers/FAF109 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF109 CR_1.webp")
    },
    {
        id: 62,
        name: "FAF559 CR",
        color: "Chrome mixer",
        design: "Compact and modern mixer with a flexible hand shower, with black horse sheath",
        description: "Secure and comfortable attachment of the hand shower to the practical mounting bracket",
        image: require("../../assets/NewKitchenMixers/FAF559 CR_2.webp"),
        image_2: require("../../assets/NewKitchenMixers/FAF559 CR_1.webp")
    },
]
