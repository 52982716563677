import React, {useEffect} from 'react';
import ProductGrid from "../../../../components/prodotti/ProductGrid";
import {islandHoodsList} from "../../../../data/hoods/hoods-data";

const IslandHoods = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={islandHoodsList} name={"Our Island Hoods"}/>
    );
};

export default IslandHoods;
