import React from 'react';

interface AvailableColoursProps {
    colori: string
}

const AvailableColours = ({colori}: AvailableColoursProps) => {
    return (
        <div className={"mt-4 space-y-3"}>
            <div className={"font-semibold text-gray-900"}>
                Available colours
            </div>
            <img src={colori} alt=""/>
        </div>
    );
};

export default AvailableColours;
