import React, {useEffect} from 'react';
import {listaNemoMix} from "../../../../../data/sinks/granit-sinks";
import ProductGrid from "../../../../../components/prodotti/ProductGrid";

const GranitNemosMixPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ProductGrid prodotti={listaNemoMix} name={"Our Granit Nemo Mix"}/>
        </>
    );
};

export default GranitNemosMixPage;
