import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaInoxHobs} from "../../../../../data/hobs/hobs-data";
import FeaturesSection from "../../../../../components/FeaturesSection";

const Prodotto = () => {


    const {id} = useParams()

    const prodotto = listaInoxHobs.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Security Valves',
            description: prodotto?.security_valves ? "Yes" : "No"
        },
        {
            name: 'Surface Material',
            description: prodotto?.surface_material ? prodotto?.surface_material : "Non Disponibile"
        },
        {
            name: 'Pan Supports',
            description: prodotto?.pan_supports
        },
        {
            name: 'Serigraphy',
            description: prodotto?.serigraphy ? prodotto?.serigraphy : "Non Disponibile"
        },
        {
            name: 'Type of controls',
            description: prodotto?.type_of_controls
        },
        {
            name: 'Cook zones',
            description: prodotto?.cooking_zones
        },
        {
            name: 'Automatic ignition',
            description: prodotto?.automatic_ignition ? "Yes" : "No"
        },
        {
            name: 'Safety Device',
            description: prodotto?.safety_device ? "Yes" : "No"
        },
        {
            name: 'Top Material',
            description: prodotto?.top_material ? prodotto?.top_material : "Non Disponibile"
        },
        {
            name: "Top Dimensions",
            description: prodotto?.top_dimensions ? prodotto?.top_dimensions : "Non Disponibile"
        },
        {
            name: "Cut Out Dimensions",
            description: prodotto?.cut_out ? prodotto?.cut_out : "Non Disponibile"
        },
        {
            name: "Electric Plates",
            description: prodotto?.electric_plates ? prodotto?.electric_plates : "Non Disponibile"
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                    {
                        prodotto?.image !== prodotto?.main_image ? (
                            <img src={prodotto?.main_image!} alt={""}/>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default Prodotto;
