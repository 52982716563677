import {GranitInterface} from "../interfaces";

export const listaNemo: GranitInterface[] = [
    {
        id: 1,
        name: "nemo 55 x 44 1V",
        base: "600mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "500x400mm R5",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Nemo/400300_2.webp"),
        image_2: require("../../assets/Granit/Nemo/400300_1.webp")
    },
    {
        id: 2,
        name: "nemo 76 x 44 1V",
        base: "800mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "720x400mm R5",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Nemo/400301_2.webp"),
        image_2: require("../../assets/Granit/Nemo/400301_1.webp")
    },
    {
        id: 3,
        name: "nemo 76 x 43.5 2V",
        base: "800mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "500x395mm R5",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Nemo/400302_2.webp"),
        image_2: require("../../assets/Granit/Nemo/400302_1.webp")
    }
]

export const listaNemoMix: GranitInterface[] = [
    {
        id: 1,
        name: "Nemo mix 57 x 50 1V",
        base: "600mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "550x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/NemoMix/400400_2.webp"),
        image_2: require("../../assets/Granit/NemoMix/400400_1.webp")
    },
    {
        id: 2,
        name: "Nemo mix 79 x 50 1V",
        base: "800mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/NemoMix/400401_2.webp"),
        image_2: require("../../assets/Granit/NemoMix/400401_1.webp")
    },
    {
        id: 3,
        name: "Nemo mix 79 x 50 2V",
        base: "800mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/NemoMix/400402_2.webp"),
        image_2: require("../../assets/Granit/NemoMix/400402_1.webp")
    }
]

export const listaOrlando: GranitInterface[] = [
    {
        id: 1,
        name: "Orlando 86 x 51 1V",
        base: "450mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "built-in fitting hole 840x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Orlando/Orlando86511V_2.webp"),
        image_2: require("../../assets/Granit/Orlando/Orlando86511V_1.webp")
    },
    {
        id: 2,
        name: "Orlando 100 x 51 1V",
        base: "600mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "built-in fitting hole 980x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Orlando/Orlando100511V_2.webp"),
        image_2: require("../../assets/Granit/Orlando/Orlando100511V_1.webp")
    },
    {
        id: 3,
        name: "Orlando 116 x 51 2V",
        base: "800mm",
        bowl_depth: "200mm",
        undertop_fitting_hole: "built-in fitting hole 1140x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Orlando/Orlando116512V_2.webp"),
        image_2: require("../../assets/Granit/Orlando/Orlando116512V_1.webp")
    },
]
export const listaRomeo: GranitInterface[] = [
    {
        id: 1,
        name: "Romeo 86 x 50 1V",
        base: "450mm",
        bowl_depth: "190mm",
        undertop_fitting_hole: "built-in fitting hole 840x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Romeo/Romeo86501V_2.webp"),
        image_2: require("../../assets/Granit/Romeo/Romeo86501V_1.webp")
    },
    {
        id: 2,
        name: "Romeo 86 x 50 2V",
        base: "900mm",
        bowl_depth: "190mm",
        undertop_fitting_hole: "built-in fitting hole 840x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Romeo/Romeo86502V_2.webp"),
        image_2: require("../../assets/Granit/Romeo/Romeo86502V_1.webp")
    },
    {
        id: 3,
        name: "Romeo 116 x 50 2V",
        base: "800mm",
        bowl_depth: "190mm",
        undertop_fitting_hole: "built-in fitting hole 1140x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/Granit/Romeo/Romeo116502V_2.webp"),
        image_2: require("../../assets/Granit/Romeo/Romeo116502V_1.webp")
    },
]
