import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import FeaturesSection from "../../../../components/FeaturesSection";
import {listaOvens} from "../../../../data/ovens/oven-data";

const Oven = () => {

    const {id} = useParams()

    const prodotto = listaOvens.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Platform',
            description: prodotto?.platform
        },
        {
            name: 'Fan',
            description: prodotto?.fan
        },
        {
            name: 'Cavity',
            description: prodotto?.cavity
        },
        {
            name: 'Energy Class',
            description: prodotto?.energy_class
        },
        {
            name: 'Functions',
            description: prodotto?.functions
        },
        {
            name: 'Chrome Side Racks',
            description: prodotto?.chromed_side_racks
        },
        {
            name: 'Tray',
            description: prodotto?.tray
        },
        {
            name: 'Grid',
            description: prodotto?.grid
        },
        {
            name: 'Door Closing',
            description: prodotto?.door_closing
        },
        {
            name: "Door Glass",
            description: prodotto?.door_glass
        },
        {
            name: "Cavity Enamel",
            description: prodotto?.cavity_enamel
        },
        {
            name: "Internal Lamp",
            description: prodotto?.internal_lamp
        },
        {
            name: "Handle",
            description: prodotto?.handle
        },
        {
            name: "Knobs",
            description: prodotto?.knobs
        },
        {
            name: "Door Aestetic",
            description: prodotto?.door_aestetic
        },
        {
            name: "Programmer",
            description: prodotto?.programmer
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default Oven;
