import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaKitchenMixer} from "../../../../../data/mixers/kitchen-mixers";
import FeaturesSection from "../../../../../components/FeaturesSection";
import {NewKitchenMixersList} from "../../../../../data/mixers/new-kitchen-mixers";

const KitchenMixer = () => {

    const {id} = useParams()

    const prodotto =  listaKitchenMixer.concat(NewKitchenMixersList).find((prodotto) => prodotto.id === parseInt(id as string))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const features: Feature[] = [
        {
            name: "Description",
            description: prodotto?.description
        },
        {
            name: "Color",
            description: prodotto?.color
        },
        {
            name: "Description",
            description: prodotto?.description
        },
    ]

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default KitchenMixer;
