import {useEffect} from "react";
import homeImage from "../assets/HomeImage.webp"
import {useInView} from "react-intersection-observer";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [textLeft, inViewTextLeft] = useInView({
        threshold: 0,
    });

    const [imageRight, inViewImageRight] = useInView({
        threshold: 0,
    });

    const [i1, inViewI1] = useInView({
        threshold: 0,
    });

    const [i2, inViewI2] = useInView({
        threshold: 0,
    });

    const [i3, inViewI3] = useInView({
        threshold: 0,
    });


    const incentives = [
        {
            name: 'Free shipping',
            imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
            description: "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
            inView: inViewI1,
            animation: "animate-fade-right sm:animate-fade-up",
            ref: i1
        },
        {
            name: '10-year warranty',
            imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
            description: "If it breaks in the first 10 years we'll replace it. After that you're on your own though.",
            inView: inViewI2,
            animation: "animate-fade-left sm:animate-fade-up",
            ref: i2
        },
        {
            name: 'Exchanges',
            imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
            description:
                "If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though.",
            inView: inViewI3,
            animation: "animate-fade-right sm:animate-fade-up",
            ref: i3
        },
    ]


    return (
        <div className="mx-auto max-w-7xl py-10 lg:py-20 sm:px-2 lg:px-4">
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                {/*HEADER*/}
                <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
                    <div ref={textLeft} className={inViewTextLeft ? `animate-fade-right` : ""}>
                        <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                            WELCOME TO OUR FAMILY!
                        </h2>
                        <p className="mt-4 text-gray-500">
                            FAF COMPANY is an Italian company founded in 2018 by Mr. Frasca, a man who has more than
                            20 years’ experience in the field of kitchen and bathroom appliances.
                            FAF COMPANY aims to be at the forefront of innovation and product quality both
                            domestically and abroad, by becoming the first choice for equipping bathrooms and
                            kitchens in your homes.
                        </p>
                    </div>
                    <div ref={imageRight}
                         className={`aspect-w-3 aspect-h-2 overflow-hidden rounded-lg bg-gray-100 ${inViewImageRight ? "animate-fade-left animate-ease-linear animate-delay-100" : ""} `}>
                        <img
                            src={homeImage}
                            alt=""
                            className="object-cover object-center"
                        />
                    </div>
                </div>
                {/*INCENTIVES*/}
                <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
                    {
                        incentives.map((incentive) => (
                            <div key={incentive.name} className={`sm:flex lg:block ${incentive.inView ? incentive.animation : ""}`} ref={incentive.ref}>
                                <div className="sm:flex-shrink-0">
                                    <img className="h-16 w-16" src={incentive.imageSrc} alt=""/>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                    <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                                    <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Home
