import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaElectricHobs} from "../../../../../data/hobs/hobs-data";
import FeaturesSection from "../../../../../components/FeaturesSection";

const Prodotto = () => {

    const {id} = useParams()

    const prodotto = listaElectricHobs.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Number of Zones',
            description: prodotto?.number_of_zones.toString()
        },
        {
            name: 'Zones',
            description: prodotto?.zones
        },
        {
            name: 'Infrared Touch Control',
            description: prodotto?.infrared_touch_control
        },
        {
            name: 'Power',
            description: prodotto?.power
        },
        {
            name: 'Total Power',
            description: prodotto?.total_power
        },
        {
            name: 'Timer for Each Zone',
            description: prodotto?.timer_for_each_zone
        },
        {
            name: 'Pot Detection',
            description: prodotto?.pot_detection
        },
        {
            name: 'Residual Heat Indicator',
            description: prodotto?.residual_heat_indicator
        },
        {
            name: 'Anti Overheat Device',
            description: prodotto?.anti_overheat_device
        },
        {
            name: 'Child Lock',
            description: prodotto?.child_lock
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image} alt={""}/>
                    <img src={prodotto?.image_2} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default Prodotto;
