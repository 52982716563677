import React, {useEffect} from 'react';
import {products} from "../../../data/productListForGrid";
import ProductListGrid from "../ProductListGrid";

const MixersPage = () => {

    const mixers = products.filter((product) => product.category === "mixers");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductListGrid
            title={"All Our Mixers"}
            products={mixers}
        />
    )
};

export default MixersPage;
