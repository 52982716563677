import {Fragment, useState} from 'react'
import {Dialog, Disclosure, Popover, Transition} from '@headlessui/react'
import {ArchiveBoxIcon, Bars3Icon, FireIcon, FunnelIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import logo from '../assets/loghi/LogoNomeRef.webp'
import {Link} from "react-router-dom";
import {FaShower, FaSink} from "react-icons/fa";
import {GrapeIcon} from "lucide-react";


const navigation = [
    {name: 'Home', to: '/'},
    {name: 'About', to: '/about'},
    {name: 'Contatti', to: '/contatti'},
    {name: 'Catalogo', to: '/catalogo'},
]

const products = [
    {
        name: 'Hobs',
        description: 'Our hobs are designed to make cooking a breeze',
        href: '/prodotti/hobs',
        icon: FireIcon,
    },
    {
        name: 'Ovens',
        description: 'Our ovens are designed to make cooking a breeze',
        href: '/prodotti/ovens-page',
        icon: ArchiveBoxIcon,
    },
    {
        name: 'Mixers',
        description: 'Our mixers are designed to make cooking a breeze',
        href: '/prodotti/mixers',
        icon: FaShower,
    },
    {
        name: 'Sinks',
        description: 'Our sinks are designed to make cooking a breeze',
        href: '/prodotti/sinks',
        icon: FaSink,
    },
    {
        name: 'Hoods',
        description: 'Our hoods are designed to make cooking a breeze',
        href: '/prodotti/hoods',
        icon: FunnelIcon,
    },
    {
        name: 'Cellars',
        description: 'Our Cellars are designed to make cooking a breeze',
        href: '/prodotti/Cellars',
        icon: GrapeIcon,
    },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}


export const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="relative isolate z-10 bg-white">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to={"/"} className="-m-1.5 p-1.5">
                        <span className="sr-only">FAF Company</span>
                        <img className="h-16 w-auto" src={logo} alt={""}/>
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>

                <Popover.Group
                    className="hidden lg:flex lg:gap-x-12 border-transparent focus:border-transparent focus:ring-0">
                    <Popover>
                        <Popover.Button
                            className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 border-transparent focus:border-transparent focus:ring-0">
                            Product
                            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 -translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">

                                {({close}) => (
                                    <>
                                        <div
                                            className="mx-auto grid max-w-7xl grid-cols-3 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                                            {
                                                products.map((item) => (
                                                    <Popover.Button
                                                        key={item.name}
                                                        className="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50"
                                                    >
                                                        <div
                                                            className="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                            <item.icon
                                                                className={
                                                                    item.name === 'Hoods'
                                                                        ? "h-6 w-6 text-gray-600 group-hover:text-indigo-600 rotate-180"
                                                                        : "h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                                                }
                                                                aria-hidden="true"/>
                                                        </div>
                                                        <Link
                                                            to={item.href}
                                                            className="mt-6 block font-semibold text-gray-900"
                                                            onClick={() => close()}
                                                        >
                                                            {item.name}
                                                            <span className="absolute inset-0"/>
                                                        </Link>
                                                        <div className="mt-1 text-gray-600">{item.description}</div>
                                                    </Popover.Button>
                                                ))
                                            }
                                        </div>
                                        {/*<div className="bg-gray-50">*/}
                                        {/*    <div className="mx-auto max-w-7xl px-6 lg:px-8">*/}
                                        {/*        <div*/}
                                        {/*            className="grid grid-cols-1 divide-x divide-gray-900/5 border-x border-gray-900/5">*/}
                                        {/*            <Link*/}
                                        {/*                onClick={() => close()}*/}
                                        {/*                to={"/prodotti"}*/}
                                        {/*                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"*/}
                                        {/*            >*/}
                                        {/*                <RectangleGroupIcon*/}
                                        {/*                    className="h-5 w-5 flex-none text-gray-400"*/}
                                        {/*                    aria-hidden="true"*/}
                                        {/*                />*/}
                                        {/*                View all products*/}
                                        {/*            </Link>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </>
                                )}


                            </Popover.Panel>
                        </Transition>

                    </Popover>
                    {
                        navigation.map((item, i) => (
                            <Link
                                key={i}
                                to={item.to}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                {item.name}
                            </Link>
                        ))
                    }
                </Popover.Group>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10"/>
                <Dialog.Panel
                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to={"/"} className="-m-1.5 p-1.5">
                            <span className="sr-only">FAF Company</span>
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt=""
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({open}) => (
                                        <>
                                            <Disclosure.Button
                                                className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                                Product
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {
                                                    [...products].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as={Link}
                                                            to={item.href}
                                                            onClick={() => setMobileMenuOpen(false)}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                        >
                                                            <div className={"flex space-x-4 items-center"}>
                                                                <item.icon
                                                                    className={
                                                                        item.name === 'Hoods'
                                                                            ? "h-6 w-6 text-gray-600 group-hover:text-indigo-600 rotate-180"
                                                                            : "h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                                                    }
                                                                    aria-hidden="true"/>
                                                                <div>
                                                                    {item.name}
                                                                </div>

                                                            </div>
                                                        </Disclosure.Button>
                                                    ))
                                                }
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                {
                                    navigation.map((item, i) => (
                                        <Link
                                            key={i}
                                            to={item.to}
                                            onClick={() => setMobileMenuOpen(false)}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}
