import React from 'react';

const Catalogo = () => {
    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
                src="https://player.flipsnack.com?hash=NUNFRjc5NjZBRUQrMXBteWh4YmF5cg=="
                width="100%" height="740"
                allowFullScreen
                allow="autoplay; clipboard-read; clipboard-write"
            />
        </div>
    );
};

export default Catalogo;
