import {AtecItem, ElectricHobInterface, FocusHobInterface, InoxHobs} from "../interfaces";


export const listaInoxHobs: InoxHobs[] = [
    {
        id: 1,
        name: 'AH7011ST1',
        security_valves: true,
        surface_material: 'Inox',
        pan_supports: 'Cast Iron',
        serigraphy: 'Laser/Ink',
        type_of_controls: 'Knobs',
        cooking_zones: '1 Aux 1,00 kW:2 Semi-Rapid 1,75 kW; 1 Triple ring 3,50W',
        main_image: require("../../assets/InoxHobs/AH7011ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7011ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 2,
        name: "FQ604VNGL",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3kW",
        main_image: require("../../assets/InoxHobs/FQ604VNGL_1.webp"),
        image: require("../../assets/InoxHobs/FQ604VNGL_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 3,
        name: "AH7007ST1",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 4,00 kW",
        main_image: require("../../assets/InoxHobs/AH7007ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7007ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 4,
        name: "AH7012ST1",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Enamelled",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW",
        main_image: require("../../assets/InoxHobs/AH7012ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7012ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 5,
        name: "AH7006ST1",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 4,00 kW",
        main_image: require("../../assets/InoxHobs/AH7006ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7006ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',

    },
    {
        id: 6,
        name: "AH7021ST1",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW, 1 Triple ring 3,80 kW",
        main_image: require("../../assets/InoxHobs/AH7021ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7021ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 7,
        name: "AH7009ST1",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW, 1 Triple ring 3,80 kW",
        main_image: require("../../assets/InoxHobs/AH7009ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7009ST1_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 8,
        name: "AH7022STB",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 4,00 kW",
        main_image: require("../../assets/InoxHobs/AH7022STB_1.webp"),
        image: require("../../assets/InoxHobs/AH7022STB_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 9,
        name: "SC124VPGC",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 4,00 kW",
        main_image: require("../../assets/InoxHobs/SC124VPGC_1.webp"),
        image: require("../../assets/InoxHobs/SC124VPGC_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',

    },
    {
        id: 10,
        name: "FB905VPGL",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 3,80W",
        main_image: require("../../assets/InoxHobs/FB905VPGL_1.webp"),
        image: require("../../assets/InoxHobs/FB905VPGL_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 11,
        name: "SC905VPGL",
        security_valves: true,
        surface_material: "Inox",
        pan_supports: "Cast Iron",
        serigraphy: "Laser/Ink",
        type_of_controls: "Knobs",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 kW; 1 Triple ring 3,80 KW",
        main_image: require("../../assets/InoxHobs/SC905VPGL_1.webp"),
        image: require("../../assets/InoxHobs/SC905VPGL_2.webp"),

        automatic_ignition: false,
        safety_device: false,
        top_material: '',
        top_dimensions: '',
        cut_out: '',
        electric_plates: '',
    },
    {
        id: 12,
        name: "AH7OI4BK1",
        security_valves: true,
        surface_material: "",
        pan_supports: "Cast Iron",
        serigraphy: "Ink on glass",
        type_of_controls: "",
        cooking_zones: "1 Aux 1,00 kW; 2 Semi-Rapid 1,75 kW; 1 Rapid 3,00 KW; 1 Triple ring 4,00 kW",
        main_image: require("../../assets/InoxHobs/AH7014BK1_2.webp"),
        image: require("../../assets/InoxHobs/AH7014BK1_2.webp"),

        automatic_ignition: true,
        safety_device: true,
        top_material: "Crystal",
        top_dimensions: "900 x 510 cm",
        cut_out: "",
        electric_plates: "",
    },
    {
        id: 13,
        name: "AH7027BK1 - BLACK",
        security_valves: true,
        pan_supports: "Enamelled",
        surface_material: "",
        serigraphy: "",
        type_of_controls: "",
        cooking_zones: "Auxiliary 1,00 kW; 1 Semi-Rapid 1,75 Kw; Rapid 3,00 Kw; 1 Triple ring 4kW",
        main_image: require("../../assets/InoxHobs/AH7027BK1_BLACK_1.webp"),
        image: require("../../assets/InoxHobs/AH7027BK1_BLACK_2.webp"),

        automatic_ignition: true,
        safety_device: true,
        top_material: "Steel",
        top_dimensions: "560 x 450 cm",
        cut_out: "",
        electric_plates: "",
    },
    {
        id: 14,
        name: "AH7025ST1",
        pan_supports: "Cast Iron",
        cooking_zones: " 1 Auxiliary (1,00 Kw); 1 Semirapid (1,75 Kw); 1 Rapid (3,00 Kw); 1 Triple ring (4 Kw)",
        serigraphy: "",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/AH7025ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7025ST1_1.webp"),

        automatic_ignition: true,
        top_material: "Steel",
        safety_device: true,
        cut_out: "275 x 495 cm",
        top_dimensions: "290 x 510 cm",
        electric_plates: "",
    },
    {
        id: 15,
        name: "AH7024ST1",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Enamelled",
        cooking_zones: "Auxiliary (1,00 Kw); Semi-Rapid (1,75 Kw)",
        top_material: "Steel",
        top_dimensions: "290 x 510 cm",
        cut_out: "275 x 495 cm",
        serigraphy: "",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/AH7024ST1_1.webp"),
        image: require("../../assets/InoxHobs/AH7024ST1_1.webp")
    },
    {
        id: 16,
        name: "AH7026STE",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Auxiliary (1,00 Kw); 1 Semi-Rapid (1, 75 Kw); Rapid (3,00 Kw); Triple ring (4 Kw)",
        electric_plates: "180mm 2.00Kw; 145mm 1,SOKw",
        top_material: "Steel",
        top_dimensions: "590 x 510 cm",
        cut_out: "560 x 480 cm",
        serigraphy: "",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/AH7026STE_1.webp"),
        image: require("../../assets/InoxHobs/AH7026STE_1.webp")
    },
    {
        id: 17,
        name: "AH7028STE",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Auxiliary (1,00 Kw); 1 Semi-Rapid (1, 75 Kw); Rapid (3,00 Kw); Triple ring (4 Kw)",
        electric_plates: "180mm 1.80Kw; 145mm 1,20Kw",
        top_material: "Steel + Vetroceramic",
        top_dimensions: "900 x 510 cm",
        cut_out: "860 x 480 cm",
        serigraphy: "",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/AH7028STE_1.webp"),
        image: require("../../assets/InoxHobs/AH7028STE_1.webp")
    },
    {
        id: 18,
        name: "AH7030BKG",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Auxiliary (1,00 Kw); 1 Semi-Rapid (1, 75 Kw); Rapid (3,00 Kw); Triple ring (4 Kw)",
        top_material: "Crystal",
        top_dimensions: "900 x 510 cm",
        cut_out: "860 x 480 cm",
        serigraphy: "Ink on Glass",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/AH7030BKG_1.webp"),
        image: require("../../assets/InoxHobs/AH7030BKG_1.webp")
    },
    {
        id: 19,
        name: "CI905VTEC",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Auxiliary (1,00 Kw); 1 Semi-Rapid (1, 75 Kw); Rapid (3,00 Kw); Triple ring (4 Kw)",
        top_material: "Crystal",
        top_dimensions: "900 x 510 cm",
        cut_out: "860 x 480 cm",
        serigraphy: "Ink on Glass",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/CI905VTEC_1.webp"),
        image: require("../../assets/InoxHobs/CI905VTEC_1.webp")
    },
    {
        id: 20,
        name: "VD381VEGC",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Triple ring (4 Kw)",
        top_material: "Vitroceramic",
        serigraphy: "Ink on Glass",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/VD381VEGC_1.webp"),
        image: require("../../assets/InoxHobs/VD381VEGC_1.webp")
    },
    {
        id: 21,
        name: "VD382VEGC",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Semi-Rapid (1,75 Kw); 1 Rapid (3,00 Kw)",
        top_material: "Vitroceramic",
        serigraphy: "Ink on Glass",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/VD382VEGC_1.webp"),
        image: require("../../assets/InoxHobs/VD382VEGC_1.webp")
    },
    {
        id: 22,
        name: "VD382VEGL",
        automatic_ignition: true,
        safety_device: true,
        pan_supports: "Cast Iron",
        cooking_zones: "1 Semi-Rapid (1,75 Kw); 1 Rapid (3,00 Kw)",
        top_material: "Vitroceramic",
        serigraphy: "Ink on Glass",
        security_valves: true,
        surface_material: "",
        type_of_controls: "",
        main_image: require("../../assets/InoxHobs/VD382VEGL_1.webp"),
        image: require("../../assets/InoxHobs/VD382VEGL_1.webp")
    },
]

export const listaAtecHobs: AtecItem[] = [
    {
        id: 1,
        name: "FAF604B4",
        marca: "A-Tec",
        item: "4 burner gas hob",
        panel: "Stainless steel",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "585 x 505 x 100 mm",
        image: require("../../assets/A-Tec/FAF604B4.webp")
    },
    {
        id: 2,
        name: "FAF605B4",
        marca: "A-Tec",
        item: "4 burner gas hob",
        panel: "Stainless steel",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "585 x 505 x 100 mm",
        image: require("../../assets/A-Tec/FAF605B4.webp")
    },
    {
        id: 3,
        name: "FAF606B4",
        marca: "A-Tec",
        item: "4 burner gas hob",
        panel: "Stainless steel",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "600 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF606B4.webp")
    },
    {
        id: 4,
        name: "FAF610B5",
        marca: "A-Tec",
        item: "5 burner gas hob",
        panel: "Stainless steel",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "860 x 500 x 100 mm",
        image: require("../../assets/A-Tec/FAF610B5.webp")
    },
    {
        id: 5,
        name: "FAF607B5",
        marca: "A-Tec",
        item: "5 burner gas hob",
        panel: "Stainless steel",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "760 x 500 x 100 mm",
        image: require("../../assets/A-Tec/FAF607B5.webp")
    },
    {
        id: 6,
        name: "FAF608B5",
        marca: "A-Tec",
        item: "5 burner gas hob",
        panel: "Tempered glass",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "770 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF608B5.webp")
    },
    {
        id: 7,
        name: "FAF609B5",
        marca: "A-Tec",
        item: "5 burner gas hob",
        panel: "Tempered glass",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Iron",
        pan_supports: "Cast Iron",
        knobs: "Metal",
        ignition: "Pulse",
        safety_device: "Yes",
        product_size: "870 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF609B5.webp")
    }
]

export const listaAtecHobsIta: AtecItem[] = [
    {
        id: 1,
        name: "FAF604B4",
        marca: "A-Tec",
        item: "Piano cottura a gas con 4 bruciatori",
        panel: "Acciaio inossidabile",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "585 x 505 x 100 mm",
        image: require("../../assets/A-Tec/FAF604B4.webp")
    },
    {
        id: 2,
        name: "FAF605B4",
        marca: "A-Tec",
        item: "Piano cottura a gas con 4 bruciatori",
        panel: "Acciaio inossidabile",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "585 x 505 x 100 mm",
        image: require("../../assets/A-Tec/FAF605B4.webp")
    },
    {
        id: 3,
        name: "FAF606B4",
        marca: "A-Tec",
        item: "Piano cottura a gas con 4 bruciatori",
        panel: "Acciaio inossidabile",
        power: "1.0/1.75/1.75/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "600 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF606B4.webp")
    },
    {
        id: 4,
        name: "FAF610B5",
        marca: "A-Tec",
        item: "Piano cottura a gas con 5 bruciatori",
        panel: "Acciaio inossidabile",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "860 x 500 x 100 mm",
        image: require("../../assets/A-Tec/FAF610B5.webp")
    },
    {
        id: 5,
        name: "FAF607B5",
        marca: "A-Tec",
        item: "Piano cottura a gas con 5 bruciatori",
        panel: "Acciaio inossidabile",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "760 x 500 x 100 mm",
        image: require("../../assets/A-Tec/FAF607B5.webp")
    },
    {
        id: 6,
        name: "FAF608B5",
        marca: "A-Tec",
        item: "Piano cottura a gas con 5 bruciatori",
        panel: "Vetro temperato",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "770 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF608B5.webp")
    },
    {
        id: 7,
        name: "FAF609B5",
        marca: "A-Tec",
        item: "Piano cottura a gas con 5 bruciatori",
        panel: "Vetro temperato",
        power: "1.0/1.75/1.75/2.7/3.3",
        burner_cap: "Ferro",
        pan_supports: "Griglie in ghisa",
        knobs: "Metallo",
        ignition: "Impulso",
        safety_device: "Sì",
        product_size: "870 x 510 x 100 mm",
        image: require("../../assets/A-Tec/FAF609B5.webp")
    }
]


export const listaFocusHobs: FocusHobInterface[] = [
    {
        id: 1,
        name: "F815B",
        hob_size: "60 cm glass hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/2.8 Rapid/4.0 Triple Ring",
        custom_front_knobs: "Yes",
        automatic_ignition: "Yes",
        cast_iron_grates: "Yes",
        tempered_glass: "Yes",
        image: require("../../assets/Focus-Line/Hobs/F815B.webp")
    },
    {
        id: 2,
        name: "F478AN",
        hob_size: "70 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/2.8 Rapid/4.0 Triple Ring",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F478AN-Anthracite.webp")
    },
    {
        id: 3,
        name: "F478IV",
        hob_size: "70 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/2.8 Rapid/4.0 Triple Ring",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F478IV-Oat.webp")
    },
    {
        id: 4,
        name: "F408AN",
        hob_size: "60 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/2.5 WOK Burner",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F408AN.webp")
    },
    {
        id: 5,
        name: "F408IV",
        hob_size: "60 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/2.5 WOK Burner",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F408IV.webp")
    },
    {
        id: 6,
        name: "F402AN",
        hob_size: "60 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/3.8 Triple Ring",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F402AN.webp")
    },
    {
        id: 7,
        name: "F402IV",
        hob_size: "60 cm Rustic Built-in hob",
        burners: "1 Auxiliary/1.75 Semi-Rapid/1.75 Semi-Rapid/3.8 Triple Ring",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Yes",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F402IV.webp")
    }

]
export const listaFocusHobsIta: FocusHobInterface[] = [
    {
        id: 1,
        name: "F815B",
        hob_size: "Piano cottura in vetro da 60 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/2.8 Rapido/4.0 Tripla Corona",
        custom_front_knobs: "Sì",
        automatic_ignition: "Sì",
        cast_iron_grates: "Sì",
        tempered_glass: "Sì",
        image: require("../../assets/Focus-Line/Hobs/F815B.webp")
    },
    {
        id: 2,
        name: "F478AN",
        hob_size: "Piano cottura incassato rustico da 70 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/2.8 Rapido/4.0 Tripla Corona",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F478AN-Anthracite.webp")
    },
    {
        id: 3,
        name: "F478IV",
        hob_size: "Piano cottura incassato rustico da 70 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/2.8 Rapido/4.0 Tripla Corona",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F478IV-Oat.webp")
    },
    {
        id: 4,
        name: "F408AN",
        hob_size: "Piano cottura incassato rustico da 60 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/2.5 Bruciatore WOK",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F408AN.webp")
    },
    {
        id: 5,
        name: "F408IV",
        hob_size: "Piano cottura incassato rustico da 60 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/2.5 Bruciatore WOK",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F408IV.webp")
    },
    {
        id: 6,
        name: "F402AN",
        hob_size: "Piano cottura incassato rustico da 60 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/3.8 Tripla Corona",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F402AN.webp")
    },
    {
        id: 7,
        name: "F402IV",
        hob_size: "Piano cottura incassato rustico da 60 cm",
        burners: "1 Ausiliario/1.75 Semi-Rapido/1.75 Semi-Rapido/3.8 Tripla Corona",
        custom_front_knobs: "No",
        automatic_ignition: "No",
        cast_iron_grates: "Sì",
        tempered_glass: "No",
        image: require("../../assets/Focus-Line/Hobs/F402IV.webp")
    }

]


export const listaElectricHobs: ElectricHobInterface[] = [
    {
        id: 1,
        name: "INDUZIONE 30 CM",
        number_of_zones: 2,
        zones: "1 Ø145 mm 1200 [1600] W 1 Ø210 mm 1600 [2000] W",
        infrared_touch_control: "Yes",
        power: "9 power levels + booster",
        total_power: "3600 W",
        timer_for_each_zone: "Yes",
        pot_detection: "Yes",
        residual_heat_indicator: "Yes",
        anti_overheat_device: "Yes",
        child_lock: "Yes",
        image: require("../../assets/ElectricHobs/30CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/30CM_2.webp")
    },
    {
        id: 2,
        name: "INDUZIONE 60 CM",
        number_of_zones: 4,
        zones: "2 Ø145 mm 1200 [1600] W 2 Ø210 mm 1600 [2000] W",
        infrared_touch_control: "Yes",
        power: "9 power levels + booster",
        total_power: "7200 W - Power management to 1kW",
        timer_for_each_zone: "Yes",
        pot_detection: "Yes",
        residual_heat_indicator: "Yes",
        anti_overheat_device: "Yes",
        child_lock: "Yes",
        image: require("../../assets/ElectricHobs/60CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/60CM_2.webp")
    },
    {
        id: 3,
        name: "INDUZIONE 90 CM",
        number_of_zones: 5,
        zones: "2 Ø160 mm 1600 W 2 Ø200 mm 3000 W 1 Ø250 mm 3000 W",
        infrared_touch_control: "Yes - Capacitive round touch control",
        power: "9 power levels + booster",
        total_power: "7400 W",
        timer_for_each_zone: "Yes",
        pot_detection: "Yes",
        residual_heat_indicator: "Yes",
        anti_overheat_device: "Yes - Keep warm function",
        child_lock: "Yes",
        image: require("../../assets/ElectricHobs/90CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/90CM_2.webp"),
    },

]
export const listaElectricHobsIta: ElectricHobInterface[] = [
    {
        id: 1,
        name: "INDUZIONE 30 CM",
        number_of_zones: 2,
        zones: "1 Ø145 mm 1200 [1600] W 1 Ø210 mm 1600 [2000] W",
        infrared_touch_control: "Sì",
        power: "9 livelli di potenza + booster",
        total_power: "3600 W",
        timer_for_each_zone: "Sì",
        pot_detection: "Sì",
        residual_heat_indicator: "Sì",
        anti_overheat_device: "Sì",
        child_lock: "Sì",
        image: require("../../assets/ElectricHobs/30CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/30CM_2.webp")
    },
    {
        id: 2,
        name: "INDUZIONE 60 CM",
        number_of_zones: 4,
        zones: "2 Ø145 mm 1200 [1600] W 2 Ø210 mm 1600 [2000] W",
        infrared_touch_control: "Sì",
        power: "9 livelli di potenza + booster",
        total_power: "7200 W - Gestione della potenza a 1 kW",
        timer_for_each_zone: "Sì",
        pot_detection: "Sì",
        residual_heat_indicator: "Sì",
        anti_overheat_device: "Sì",
        child_lock: "Sì",
        image: require("../../assets/ElectricHobs/60CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/60CM_2.webp")
    },
    {
        id: 3,
        name: "INDUZIONE 90 CM",
        number_of_zones: 5,
        zones: "2 Ø160 mm 1600 W 2 Ø200 mm 3000 W 1 Ø250 mm 3000 W",
        infrared_touch_control: "Sì - Controllo tattile capacitivo rotondo",
        power: "9 livelli di potenza + booster",
        total_power: "7400 W",
        timer_for_each_zone: "Sì",
        pot_detection: "Sì",
        residual_heat_indicator: "Sì",
        anti_overheat_device: "Sì - Funzione mantenimento in caldo",
        child_lock: "Sì",
        image: require("../../assets/ElectricHobs/90CM_1.webp"),
        image_2: require("../../assets/ElectricHobs/90CM_2.webp"),
    },

]
