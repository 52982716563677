import {IProduct} from "../interfaces";

export interface WineCellar extends IProduct {
    description: string,
    tag: number
    zones: string,
    temperature: string,
    energy_class: string,
    capacity: string,
    frame: string,
    door: string,
    dimensions: string,
    reversible_door: string,
    handle: string,
    shelves: string,
    light: string,
    control: string,
    humidity_control: string,
    anti_vibration_system: string,
    adjustable_feet: string,
    door_alarm: string,
    temperature_alarm: string,
    consumption: string,
    sound_level: string,
    sound_class: string,
    ventilation_system: string,
    usable_volume: string,
    climate_class: string,
    net_weight: string,
    image_2?: string
}

export const cellarsData: WineCellar[] = [
    {
        id: 1,
        tag: 45,
        name: "FAF C 23-45",
        description: "Service wine cellar",
        zones: "1",
        temperature: "+5°C - +20°C",
        energy_class: "G",
        capacity: "23 bottles",
        frame: "Black",
        door: "Glass",
        control: "Not Available",
        dimensions: "45,5x59x56,3cm (WxHxD)",
        reversible_door: "Yes",
        handle: "Black Handle",
        shelves: "2 Wooden shelves",
        light: "White LED",
        humidity_control: "No",
        anti_vibration_system: "Yes",
        door_alarm: "Automatic locking of the control panel",
        temperature_alarm: "No",
        consumption: "Not Available",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "40 dB",
        sound_class: "C",
        ventilation_system: "No",
        usable_volume: "Not Available",
        climate_class: "Not Available",
        net_weight: "Not Available",
        image: require("../../assets/Cellars/FAF C 23-45_2.webp"),
        image_2: require("../../assets/Cellars/FAF C 23-45_1.webp"),
    },
    {
        id: 2,
        tag: 45,
        name: "FAF C 24-45",
        description: "Built-in service wine cabinet",
        zones: "1",
        temperature: "+5°C - +20°C",
        energy_class: "F",
        capacity: "24 bottles",
        control: "Sensitive control with blue LED digital display",
        shelves: "3 Wooden shelves with stainless trim",
        reversible_door: "Yes",
        frame: "Black",
        door: "Glass",
        dimensions: "44,1x55,5x55,7 (WxHxD)",
        handle: "Black handle included (only for black models)",
        light: "Blue LED lighting",
        humidity_control: "No",
        anti_vibration_system: "Yes",
        door_alarm: "Automatic locking of the control panel",
        temperature_alarm: "No",
        consumption: "100kWh/year",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "37 dB",
        sound_class: "C",
        ventilation_system: "Ventilated Cold",
        usable_volume: "54 L",
        climate_class: "ST (+16°C to +38°C)",
        net_weight: "27kg",
        image: require("../../assets/Cellars/FAF C 24-45.webp"),
    },
    {
        id: 3,
        tag: 60,
        name: "FAF C 32-59",
        description: "Service wine cellar",
        zones: "1",
        temperature: "+5°C - +20°C",
        energy_class: "G",
        capacity: "32 bottles",
        control: "Electronic control",
        shelves: "4 Wooden shelves",
        reversible_door: "Yes",
        frame: "Black",
        door: "Double glazed door, anti-UV treated",
        dimensions: "59,5x59x56,3cm (WxHxD)",
        handle: "Black handle",
        light: "White LED lighting",
        humidity_control: "No",
        anti_vibration_system: "Yes",
        door_alarm: "Automatic locking of the control panel",
        temperature_alarm: "No",
        consumption: "Not Available",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "40 dB",
        sound_class: "C",
        ventilation_system: "Not Available",
        usable_volume: "Not Available",
        climate_class: "Not Available",
        net_weight: "Not Available",
        image: require("../../assets/Cellars/FAF C 32-59.webp"),
        image_2: require("../../assets/Cellars/FAF C 32-59_2.webp"),
    },
    {
        id: 4,
        tag: 60,
        name: "FAF C 34-59",
        description: "Built-in storage and service wine cabinet",
        zones: "2",
        temperature: "+5°C - +10°C  (22 bottles) +10°C - +20°C (12 bottles)",
        energy_class: "G",
        capacity: "34 bottles",
        control: "Sensitive control with blue LED digital display",
        shelves: "3 wooden shelves with stainless steel front",
        reversible_door: "Yes",
        frame: "Stainless frame door \n",
        door: "Glass",
        dimensions: "59,5x59x56,3 cm (HxWxD)",
        handle: "Black handle included (only for black models)",
        light: "Blue LED lighting",
        humidity_control: "No",
        anti_vibration_system: "Yes",
        door_alarm: "Yes",
        temperature_alarm: "Yes",
        consumption: "148 kWh/year",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "39 dB",
        sound_class: "C",
        ventilation_system: "Ventilated Cold",
        usable_volume: "75 L",
        climate_class: "ST (+16°C to +38°C)",
        net_weight: "32kg",
        image: require("../../assets/Cellars/FAF C 34-59.webp"),
    },
    {
        id: 5,
        tag: 90,
        name: "FAF C 57-88",
        description: "Service and conservation wine cellar",
        zones: "2",
        temperature: "+5°C - +12°C, +5°C - +22°C\n",
        energy_class: "G",
        capacity: "57 bottles",
        shelves: "5 sliding wooden shelves on rails, in wood",
        control: "Electronic control",
        reversible_door: "Not Available",
        frame: "Stainless frame door",
        door: "Not Available",
        dimensions: "88,5x59x56,3 cm (HxWxD)",
        handle: "Black handle",
        light: "White LED lighting",
        humidity_control: "Not Available",
        anti_vibration_system: "Not Available",
        door_alarm: "Not Available",
        temperature_alarm: "Yes",
        consumption: "Not Available",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "38 dB",
        sound_class: "C",
        ventilation_system: "Not Available",
        usable_volume: "Not Available",
        climate_class: "Not Available",
        net_weight: "Not Available",
        image: require("../../assets/Cellars/FAF C 57-88._1.webp"),
        image_2: require("../../assets/Cellars/FAF C 57-88_2.webp"),
    },
    {
        id: 6,
        tag: 90,
        name: "FAF C 30-83B",
        description: "Free-standing",
        zones: "1",
        temperature: "+5°C - +22°C",
        energy_class: "G",
        capacity: "30 bottles",
        shelves: "3 wooden shelves with wooden front + 1 half",
        control: "Electronic control with blue LED digital display",
        reversible_door: "No",
        frame: "Black",
        door: "Stainless steel door bottom",
        dimensions: "83 x 48 x 54 cm (HxWxD)",
        handle: "Stainless steel external handle",
        light: "Blue LED lighting",
        humidity_control: "Not Available",
        anti_vibration_system: "Not Available",
        door_alarm: "Automatic locking of the control panel",
        temperature_alarm: "Yes",
        consumption: "129 kWh/year",
        adjustable_feet: "2 Adjustable feet",
        sound_level: "40 dB",
        sound_class: "C",
        ventilation_system: "Ventilated cold",
        usable_volume: "115L",
        climate_class: "N (+16°C to +32°C)",
        net_weight: "31kg",
        image: require("../../assets/Cellars/FAF C 30-83B.webp"),
    },
]
