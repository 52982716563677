import ProductListGrid from "../../ProductListGrid";

const DimensionCellarGird = () => {

    const cellars = [
        {
            id: 1,
            name: 'Cellars 45cm',
            href: '/prodotti/Cellars/45',
            imageSrc: require("../../../../assets/Cellars/FAF C 23-45_2.webp"),
            category: 'Cellars'
        },
        {
            id: 2,
            name: 'Cellars 60cm',
            href: '/prodotti/Cellars/60',
            imageSrc: require("../../../../assets/Cellars/FAF C 34-59.webp"),
            category: 'Cellars'
        },
        {
            id: 3,
            name: 'Cellars 90cm',
            href: '/prodotti/Cellars/90',
            imageSrc: require("../../../../assets/Cellars/FAF C 57-88._1.webp"),
            category: 'Cellars'
        },

    ]

    return (
        <ProductListGrid
            title={"All Our Cellars"}
            products={cellars}
        />
    );
};

export default DimensionCellarGird;
