import React, {useEffect} from 'react';
import {products} from "../../../data/productListForGrid";
import ProductListGrid from "../ProductListGrid";

const SinksPage = () => {

    const sinks = products.filter((product) => product.category === "sinks");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductListGrid
            title={"All Our Sinks"}
            products={sinks}
        />
    )
};

export default SinksPage;
