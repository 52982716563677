import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaFocusHobs} from "../../../../../data/hobs/hobs-data";
import FeaturesSection from "../../../../../components/FeaturesSection";

const Prodotto = () => {

    const {id} = useParams()

    const prodotto = listaFocusHobs.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Hob Size',
            description: prodotto?.hob_size
        },
        {
            name: 'Burners',
            description: prodotto?.burners
        },
        {
            name: 'Custom Front Knobs',
            description: prodotto?.custom_front_knobs
        },
        {
            name: 'Automatic Ignition',
            description: prodotto?.automatic_ignition
        },
        {
            name: 'Cast Iron Grates',
            description: prodotto?.cast_iron_grates
        },
        {
            name: 'Tempered Glass',
            description: prodotto?.tempered_glass
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default Prodotto;
