import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import FeaturesSection from "../../../../../components/FeaturesSection";
import {listaFocusLineOvens} from "../../../../../data/ovens/oven-data";

const FocusLineOven = () => {

    const {id} = useParams()

    const prodotto = listaFocusLineOvens.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Platform',
            description: prodotto?.type
        },
        {
            name: 'Fan',
            description: prodotto?.color
        },
        {
            name: 'Cavity',
            description: prodotto?.energy_class
        },
        {
            name: 'Energy Class',
            description: prodotto?.capacity
        },
        {
            name: 'Functions',
            description: prodotto?.dimension
        },
        {
            name: 'Chrome Side Racks',
            description: prodotto?.timer
        },
        {
            name: 'Tray',
            description: prodotto?.knobs
        },
        {
            name: 'Grid',
            description: prodotto?.door
        },
        {
            name: 'Door Closing',
            description: prodotto?.interns
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default FocusLineOven;
