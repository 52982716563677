import React, {useEffect} from 'react';
import {listaRomeo} from "../../../../../data/sinks/granit-sinks";
import ProductGrid from "../../../../../components/prodotti/ProductGrid";

const GranitOrlandoPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ProductGrid prodotti={listaRomeo} name={"Our Granit Romeo"}/>
        </>
    );
};

export default GranitOrlandoPage;
