import React, {useEffect} from 'react';
import {listaInoxSinks} from "../../../../data/sinks/inox-sinks";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const KitchenMixers = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaInoxSinks} name={"Our Inox Sinks"}/>
    );
};

export default KitchenMixers;
