import {useEffect} from 'react';
import ProductGrid from "../../../../components/prodotti/ProductGrid";
import {cellarsData} from "../../../../data/cellars/cellars-data";
import {useParams} from "react-router-dom";

const Cellars = () => {

    const {tag} = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid
            prodotti={cellarsData.filter((item) => item.tag === parseInt(tag!))}
            name={"Our Cellars " + parseInt(tag!) + "cm"}
        />
    );
};

export default Cellars;
