import React, {useEffect} from 'react';
import ProductGrid from "../../../../components/prodotti/ProductGrid";
import {builtInHoodsList} from "../../../../data/hoods/hoods-data";

const BuiltInHoods = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={builtInHoodsList} name={"Our Built-In Hoods"}/>
    );
};

export default BuiltInHoods;
