import React, {useEffect} from 'react';
import {listaFocusLineOvens} from "../../../../data/ovens/oven-data";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const Ovens = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaFocusLineOvens} name={"Our Focus Line Ovens"}/>
    );
};

export default Ovens;
