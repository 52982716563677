import React, {useEffect} from 'react';
import {products} from "../../../data/productListForGrid";
import ProductListGrid from "../ProductListGrid";

const OvensPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const ovens = products.filter((product) => product.category === "ovens");

    return (
        <ProductListGrid
            title={"All Our Ovens"}
            products={ovens}
        />
    );
};

export default OvensPage;
