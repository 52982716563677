import React, {useEffect} from 'react';
import {listaInoxHobs} from "../../../../data/hobs/hobs-data";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const InoxHobsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaInoxHobs} name={"Our Inox hobs"}/>
    );
};

export default InoxHobsPage;
