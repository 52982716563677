import React from 'react';
import ProductListGrid from "../../ProductListGrid";

const GranitSinks = () => {

    const x = [
        {
            id: 9,
            name: 'Granit Nemo',
            href: '/prodotti/granit-nemo',
            imageSrc: require("../../../../assets/Granit/Nemo/nemo.webp"),
            category: 'sinks'
        },
        {
            id: 10,
            name: 'Granit Nemo Mix',
            href: '/prodotti/granit-nemo-mix',
            imageSrc: require("../../../../assets/Granit/NemoMix/nemo-mix.webp"),
            category: 'sinks'
        },
        {
            id: 11,
            name: 'Granit Orlando',
            href: '/prodotti/granit-orlando',
            imageSrc: require("../../../../assets/Granit/Orlando/OrlandoCover.webp"),
            category: 'sinks'
        },
        {
            id: 12,
            name: 'Granit Romeo',
            href: '/prodotti/granit-romeo',
            imageSrc: require("../../../../assets/Granit/Romeo/RomeoCover.webp"),
            category: 'sinks'
        }
    ]

    return (
        <ProductListGrid products={x} title={"Our Granit Sinks"}/>
    );
};

export default GranitSinks;
