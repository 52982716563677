import React, {useEffect} from 'react';
import {listaBathroomMixers} from "../../../../data/mixers/bathroom-mixers";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const KitchenMixers = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaBathroomMixers} name={"Our Bathroom Mixers"}/>
    );
};

export default KitchenMixers;
