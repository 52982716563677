import React, {useEffect} from "react";
import ProductListGrid from "./ProductListGrid";
import {products} from "../../data/productListForGrid";


const CategorieProdotti = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductListGrid
            title={"All Our Products"}
            products={products}
        />
    )
}

export default CategorieProdotti
