import React, {useEffect} from 'react';
import ProductGrid from "../../../../../components/prodotti/ProductGrid";
import {listaMethacrylate} from "../../../../../data/sinks/methacrylate-sinks";


const ProvenzaSinks = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ProductGrid prodotti={listaMethacrylate} name={"Our Provenza Sinks"}/>
        </>
    );
};

export default ProvenzaSinks;
