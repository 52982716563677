import {IInoxSink} from "../interfaces";

export const listaInoxSinks: IInoxSink[] = [
    {
        id: 1,
        name: "FAF Mondial 75x50 1V + 1G",
        base: "450mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL75501VG.webp")
    },
    {
        id: 2,
        name: "FAF Mondial 75x50 2V",
        base: "800/900mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL75502V.webp")
    },
    {
        id: 3,
        name: "FAF Mondial 89x50 1V + 1G",
        base: "800/900mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL89501VG.webp")
    },
    {
        id: 4,
        name: "FAF Mondial 89x50 2V",
        base: "800/900mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL89502V.webp")
    },
    {
        id: 5,
        name: "FAF Mondial 100x50 2V + 1G",
        base: "600/800mm",
        bowl_depth: "160/100mm",
        built_in_fitting_hole: "975x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL89502V.webp")
    },
    {
        id: 6,
        name: "FAF Mondial 116x50 2V + 1G",
        base: "800/900mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Mondial/FAFMONDIAL89502V.webp")
    },
    {
        id: 7,
        name: "FAF Pisa 79x50 1V + 1G",
        base: "450mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "770x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Pisa/FAFPISA79501VG.webp")
    },
    {
        id: 8,
        name: "FAF Pisa 86x50 1V + 1G",
        base: "450mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "835x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Pisa/FAFPISA86501VG.webp")
    },
    {
        id: 9,
        name: "FAF Pisa 86x50 2V",
        base: "800mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "835x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Pisa/FAFPISA86502V.webp")
    },
    {
        id: 10,
        name: "FAF Pisa 100x50 2V",
        base: "600/800mm",
        bowl_depth: "160/100mm",
        built_in_fitting_hole: "975x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Pisa/FAFPISA100502V.webp")
    },
    {
        id: 11,
        name: "FAF Pisa 116x50 2V",
        base: "800/900mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Pisa/FAFPISA116502V.webp")
    },
    {
        id: 12,
        name: "FAF Cama 116x50 2V",
        base: "600/800mm",
        bowl_depth: "190mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFCAMA116502V.webp")
    },
    {
        id: 13,
        name: "FAF Cyrano 116x50 2V",
        base: "800/900mm",
        bowl_depth: "210mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFCYRANO116502V.webp")
    },
    {
        id: 14,
        name: "FAF Zen 116x50 2V",
        base: "800/900mm",
        bowl_depth: "210mm",
        built_in_fitting_hole: "1140x480mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFZEN116502V.webp")
    },
    {
        id: 15,
        name: "FAF Alba 116x50 2V",
        base: "800/900mm",
        bowl_depth: "190/180mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFALBA116502V.webp")
    },
    {
        id: 16,
        name: "FAF Leva 116x50 2V",
        base: "800/900mm",
        bowl_depth: "210mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFLEVA116502V.webp")
    },
    {
        id: 17,
        name: "FAF Planet 116x50 2V",
        base: "800/900mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFPLANET116502V.webp")
    },
    {
        id: 18,
        name: "FAF Nietzsche 116x50 2V",
        base: "800/900mm",
        bowl_depth: "150mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFNIETZSCHE116502V.webp")
    },
    {
        id: 19,
        name: "FAF Sirio 116x50 2V",
        base: "800/900mm",
        bowl_depth: "210mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFSIRIO116502V.webp")
    },
    {
        id: 20,
        name: "FAF Zenplus 116x50 2V",
        base: "800/900mm",
        bowl_depth: "210mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFZENPLUS116502V.webp")
    },
    {
        id: 21,
        name: "FAF Estella 116x50 2V",
        base: "800/900mm",
        bowl_depth: "190mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFESTELLA116502V.webp")
    },
    {
        id: 22,
        name: "FAF Aurora 116x50 2V",
        base: "800/900mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "1140x400mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFAURORA116502V.webp")
    },
    {
        id: 23,
        name: "FAF Goblin 116x50 2V",
        base: "800/900mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFGOBLIN116502V.webp")
    },
    {
        id: 24,
        name: "FAF Atlantide 116x50 2V",
        base: "800/900mm",
        bowl_depth: "170mm",
        built_in_fitting_hole: "1135x475mm",
        standard_manual_waste_kit: "Yes",
        image: require("../../assets/InoxSink/Generic/FAFATLANTIDE116502V.webp")
    },
]
