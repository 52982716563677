import {IHoods} from "../interfaces";

export const wallHoodsList: IHoods[] = [
    {
        id: 1,
        name: 'FAF 101WA',
        dimensions: '90 cm',
        finishing: 'Inox AISI 304 + black or white hardened glass',
        version: 'Duct out',
        controls: 'Touch control 3S + B',
        lights: 'LED 5x1,5 W',
        air_flow: '258-553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W max',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 101WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 101WA_1.webp')
    },
    {
        id: 2,
        name: 'FAF 102WA',
        dimensions: '90 cm',
        finishing: 'Inox + black hardened glass',
        version: 'Duct out',
        controls: 'Touch control 3S + B',
        lights: 'LED 5x1,2 W',
        air_flow: '226-494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W motor',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'N/A',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 102WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 102WA_1.webp')
    },
    {
        id: 3,
        name: 'FAF 103WA',
        dimensions: '86 cm',
        finishing: 'Inox AISI 304 + black hardened glass',
        version: 'Filtering',
        controls: 'Touch control 3S + B',
        lights: 'Neon 1x18 W',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W (max)',
        noise_level: '46 -63 (72) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'B',
        chimneys: 'Optional',
        image: require('../../assets/Hoods/Wall/FAF 103WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 103WA_1.webp')
    },
    {
        id: 4,
        name: 'FAF 104WA',
        dimensions: '60 - 90 cm',
        finishing: 'Inox + black or white hardened glass',
        version: 'Duct out (telescopic chimneys included)',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W max',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 104WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 104WA_1.webp')
    },
    {
        id: 5,
        name: 'FAF 105WA',
        dimensions: '86 cm',
        finishing: 'Inox + black hardened glass',
        version: 'Duct out (telescopic chimneys included)',
        controls: 'Touch control 3S + B',
        lights: 'Neon 2 x 24 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W (max)',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'B',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 105WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 105WA_1.webp')
    },
    {
        id: 6,
        name: 'FAF 106WA',
        dimensions: '65 cm',
        finishing: 'Inox AISI 304 + black or white hardened glass',
        version: 'Recycling',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W (max)',
        noise_level: '46 -63 (72) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 106WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 106WA_1.webp')
    },
    {
        id: 7,
        name: 'FAF 107WA',
        dimensions: '65 cm',
        finishing: 'Inox AISI 304 + black or white hardened glass',
        version: 'Filtering',
        controls: 'Touch control 3S + B',
        lights: 'LED',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W (max)',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'Optional',
        image: require('../../assets/Hoods/Wall/FAF 107WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 107WA_1.webp')
    },
    {
        id: 8,
        name: 'FAF 108WA',
        dimensions: '90 / 120 cm (hood) - 90 cm',
        finishing: 'Inox AISI 304 + black or white',
        version: 'Filtering',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '630 m3/h',
        pressure: '335 Pa',
        power: '375 W (max hood) - 115 W (bracket)',
        noise_level: '73 dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'Optional',
        image: require('../../assets/Hoods/Wall/FAF 108WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 108WA_1.webp')
    },
    {
        id: 9,
        name: 'FAF 109WA',
        dimensions: '90 / 120 cm (hood) - 90 cm (bracket)',
        finishing: 'Inox AISI 304',
        version: 'Filtering',
        controls: 'Soft Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '630 m3/h',
        pressure: '335 Pa',
        power: '375 W (max hood) - 115 W (bracket)',
        noise_level: '73 dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'Optional',
        image: require('../../assets/Hoods/Wall/FAF 109WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 109WA_1.webp')
    },
    {
        id: 10,
        name: 'FAF 110WA',
        dimensions: '86 cm',
        finishing: 'Inox + black hardened glass',
        version: 'Duct out (telescopic chimneys included)',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 110WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 110WA_1.webp')
    },
    {
        id: 11,
        name: 'FAF 111WA',
        dimensions: '90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 111WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 111WA_1.webp')
    },
    {
        id: 12,
        name: 'FAF 112WA',
        dimensions: '90 - 120 cm',
        finishing: 'Inox + black hardened glass',
        version: 'Duct out',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 112WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 112WA_1.webp')
    },
    {
        id: 13,
        name: 'FAF 113WA',
        dimensions: '80 x 80 cm',
        finishing: 'Inox + black or white hardened glass',
        version: 'Duct out (telescopic chimneys included)',
        controls: 'Touch control 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 113WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 113WA_1.webp')
    },
    {
        id: 14,
        name: 'FAF 114WA',
        dimensions: '90 - 120 cm',
        finishing: 'Inox / painted graphite or white',
        version: 'Duct out',
        controls: 'Push buttons 3V',
        lights: 'LED 2 x 1,2W',
        air_flow: '260 - 625 m3/h',
        pressure: '534 Pa',
        power: '210 W',
        noise_level: '53 - 67 dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 114WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 114WA_1.webp')
    },
    {
        id: 15,
        name: 'FAF 115WA',
        dimensions: '90 cm',
        finishing: 'Inox hardened glass',
        version: 'Duct out',
        controls: 'Electronic soft touch 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 115WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 115WA_1.webp')
    },
    {
        id: 16,
        name: 'FAF 116WA',
        dimensions: '45 x 45 cm',
        finishing: 'Inox + black or white glass',
        version: 'Duct out',
        controls: 'Touch screen 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 116WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 116WA_1.webp')
    },
    {
        id: 17,
        name: 'FAF 117WA',
        dimensions: '90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Soft Touch 3S + B',
        lights: 'LED 2 x 1,2W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 117WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 117WA_1.webp')
    },
    {
        id: 18,
        name: 'FAF 118WA',
        dimensions: '90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Push buttons 3V',
        lights: 'Halo 2 x 20 W',
        air_flow: '320 m3/h IEC61591 (motor)',
        pressure: '355 Pa (motor)',
        power: '170 W',
        noise_level: '64 dB(A)re 1pW (motor)',
        accessories: 'Ø 206x87mm',
        energy_class: 'N/A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 118WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 118WA_1.webp')
    },
    {
        id: 19,
        name: 'FAF 119WA',
        dimensions: '90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic soft touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 -64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 119WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 119WA_1.webp')
    },
    {
        id: 20,
        name: 'FAF 120WA',
        dimensions: '90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Soft Touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 -64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 120WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 120WA_1.webp')
    },
    {
        id: 21,
        name: 'FAF 121WA',
        dimensions: '60-90-120 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Push buttons 3S',
        lights: 'Halo 2 x 20 W',
        air_flow: '335 m3/h',
        pressure: '270 Pa (motor)',
        power: '190 W (max)',
        noise_level: '51 - 64 dB(A)',
        accessories: 'Ø 120mm',
        energy_class: 'D',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 121WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 121WA_1.webp')
    },
    {
        id: 22,
        name: 'FAF 122WA',
        dimensions: '60-90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Push buttons 3S',
        lights: 'Halo 2 x 28 W',
        air_flow: '335 m3/h',
        pressure: '270 Pa',
        power: '206 W (max)',
        noise_level: '51 - 64 dB(A)',
        accessories: 'Ø 120mm',
        energy_class: 'E',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 122WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 122WA_1.webp')
    },
    {
        id: 23,
        name: 'FAF 123WA',
        dimensions: '60-90 cm',
        finishing: 'Inox + Hardened glass',
        version: 'Duct out',
        controls: 'Push buttons 3S',
        lights: 'Halo 2 x 20 W',
        air_flow: '335 m3/h',
        pressure: '270 Pa (motor)',
        power: '190 W (max)',
        noise_level: '51 - 64 dB(A)',
        accessories: 'Ø 120mm',
        energy_class: 'D',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 123WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 123WA_1.webp')
    },
    {
        id: 24,
        name: 'FAF 124WA',
        dimensions: '90 cm',
        finishing: 'White and Black glass',
        version: 'Recycling (PLASMA TECHNOLOGY)',
        controls: 'Touch Screen 3S + B',
        lights: 'LED strip',
        air_flow: '190 - 580 (630) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'N/A',
        energy_class: 'N/A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Wall/FAF 124WA_2.webp'),
        image_2: require('../../assets/Hoods/Wall/FAF 124WA_1.webp')
    },
]


export const islandHoodsList: IHoods[] = [
    {
        id: 1,
        name: 'FAF 201 IS',
        dimensions: 'Ø 57 cm',
        finishing: 'Murano white blow glass',
        version: 'Recycling (PLASMA TECHNOLOGY 10W)',
        controls: 'Radio remote control 3S + B',
        lights: 'LED 10W',
        air_flow: '180 - 550 (610) m3/h',
        pressure: '590 Pa',
        power: '275 W max',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'N/A',
        energy_class: 'N/A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 201 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 201 IS_1.webp')
    },
    {
        id: 2,
        name: 'FAF 202 IS',
        dimensions: 'Ø 37 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic soft touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W max',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø Outlet 150 mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 202 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 202 IS_1.webp')
    },
    {
        id: 3,
        name: 'FAF 203 IS',
        dimensions: '100 x 70 cm',
        finishing: 'Inox AISI 304 + smoke hardened glass',
        version: 'Duct out',
        controls: 'Remote Control 3S + B',
        lights: 'Neon 4 x 8 W',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W max',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150 mm',
        energy_class: 'B',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 203 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 203 IS_1.webp')
    },
    {
        id: 4,
        name: 'FAF 204 IS',
        dimensions: '90 - 120 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Radio remote Control 3S + B',
        lights: 'LED 6 x 1,2 W',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W max',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150mm - 360° DIRECTION',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 204 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 204 IS_1.webp')
    },
    {
        id: 5,
        name: 'FAF 205 IS',
        dimensions: '100 x 60 cm',
        finishing: 'Inox + hardened white glass',
        version: 'Recycling',
        controls: 'Radio remote Control 3S + B',
        lights: 'LED strip',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W max',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150mm - 360° DIRECTION',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 205 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 205 IS_1.webp')
    },
    {
        id: 6,
        name: 'FAF 206 IS',
        dimensions: '66 cm',
        finishing: 'Inox AISI 304',
        version: 'Recycling',
        controls: 'Soft Touch 3S + B',
        lights: 'LED 2 X 1,2 W',
        air_flow: '258 - 553 (886) m3/h',
        pressure: '550 Pa',
        power: '350 W max',
        noise_level: '46 - 63 (72) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 206 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 206 IS_1.webp')
    },
    {
        id: 7,
        name: 'FAF 207 IS',
        dimensions: '45 x 45 cm',
        finishing: 'Inox + black or white glass',
        version: 'Duct out',
        controls: 'Soft Touch 3S + B',
        lights: 'LED 4 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W max',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 207 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 207 IS_1.webp')
    },
    {
        id: 8,
        name: 'FAF 208 IS',
        dimensions: '90 x 60 cm',
        finishing: 'Inox + hardened glass',
        version: 'Duct out',
        controls: 'Electronic soft touch 3S + B',
        lights: 'LED 4 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W max',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/Island/FAF 208 IS_2.webp'),
        image_2: require('../../assets/Hoods/Island/FAF 208 IS_1.webp')
    },
]
export const builtInHoodsList: IHoods[] = [
    {
        id: 1,
        name: 'FAF 301 IN',
        dimensions: '60 - 90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic Soft Touch 4S',
        lights: 'LED STRIP 3,8W x 60cm, 4,3W x 90cm',
        air_flow: '239 - 629 m3/h',
        pressure: '574 Pa',
        power: '275 W motor',
        noise_level: '50 - 69 dB(A)',
        accessories: 'N/A',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 301 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 301 IN_2.webp')
    },
    {
        id: 2,
        name: 'FAF 302 IN',
        dimensions: '60 - 90 cm',
        finishing: 'Inox',
        version: 'Recycling (PLASMA TECHNOLOGY 10W)',
        controls: 'Electronic Soft Touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '250 - 450 (580) m3/h',
        pressure: '550 Pa',
        power: '230 W',
        noise_level: '48 - 64 (69) dB(A)',
        accessories: 'N/A',
        energy_class: 'N/A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 302 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 302 IN_1.webp')
    },
    {
        id: 3,
        name: 'FAF 303 IN',
        dimensions: '60 - 90 - 120 cm',
        finishing: 'Inox + hardened glass for openable',
        version: 'Duct out',
        controls: 'Rotary control 3S',
        lights: 'LED 2 x 1,2 W',
        air_flow: '260 - 625 m3/h',
        pressure: '534 Pa',
        power: '210 W',
        noise_level: '53 - 67 dB(A)',
        accessories: 'Reduction 150-125mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 303 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 303 IN_1.webp')
    },
    {
        id: 4,
        name: 'FAF 304 IN',
        dimensions: '60 - 80 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic Soft Touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 304 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 304 IN_1.webp')
    },
    {
        id: 5,
        name: 'FAF 305 IN',
        dimensions: '60 - 80 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic Soft Touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 305 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 305 IN_1.webp')
    },
    {
        id: 6,
        name: 'FAF 306 IN',
        dimensions: '60 - 90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Electronic Soft Touch 3S + B',
        lights: 'LED 2 x 1,2 W',
        air_flow: '226 - 494 (754) m3/h',
        pressure: '590 Pa',
        power: '275 W',
        noise_level: '45 - 64 (69) dB(A)',
        accessories: 'Ø 150mm',
        energy_class: 'A',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 306 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 306 IN_1.webp')
    },
    {
        id: 7,
        name: 'FAF 307 IN',
        dimensions: '60 - 90 cm',
        finishing: 'Inox',
        version: 'Duct out',
        controls: 'Slider 3S',
        lights: 'Halo 2 x 28 W',
        air_flow: '335 m3/h',
        pressure: '270 Pa',
        power: '206 W',
        noise_level: '51 - 64 dB(A)',
        accessories: 'Ø 120mm',
        energy_class: 'E',
        chimneys: 'N/A',
        image: require('../../assets/Hoods/BuiltIn/FAF 307 IN_2.webp'),
        image_2: require('../../assets/Hoods/BuiltIn/FAF 307 IN_1.webp')
    },
]

