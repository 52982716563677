import {BathroomMixer} from "../interfaces";

export const listaBathroomMixers: BathroomMixer[] = [
    {
        id: 1,
        name: "FAF101",
        description: "BASIN MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF101.webp"),
        image_2: require("../../assets/BathroomMixers/FAF101_2.webp"),
    },
    {
        id: 2,
        name: "FAF200",
        description: "BIDET MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF200.webp"),
        image_2: require("../../assets/BathroomMixers/FAF200_2.webp"),
    },
    {
        id: 3,
        name: "FAF102",
        description: "BASIN MIXER W/O WASTE",
        image: require("../../assets/BathroomMixers/FAF102.webp"),
        image_2: require("../../assets/BathroomMixers/FAF102_2.webp"),
    },
    {
        id: 4,
        name: "FAF301",
        description: "WALL MOUNTED BATH/SHOWER MIXER WITH SHOWER KIT",
        image: require("../../assets/BathroomMixers/FAF301.webp"),
        image_2: require("../../assets/BathroomMixers/FAF301_2.webp"),
    },
    {
        id: 5,
        name: "FAF404",
        description: "EXPOSED SHOWER MIXER 1/2” OUTLET",
        image: require("../../assets/BathroomMixers/FAF404.webp"),
        image_2: require("../../assets/BathroomMixers/FAF404_2.webp"),
    },
    {
        id: 6,
        name: "FAF401",
        description: "COVER PLATE WITH LEVER FOR CONCEALED SHOWER 1 OUTLET",
        image: require("../../assets/BathroomMixers/FAF401.webp"),
        image_2: require("../../assets/BathroomMixers/FAF401_2.webp"),
    },
    {
        id: 7,
        name: "FAF505",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT",
        image: require("../../assets/BathroomMixers/FAF505.webp"),
        image_2: require("../../assets/BathroomMixers/FAF505_2.webp"),
    },
    {
        id: 8,
        name: "FAF4010",
        description: "CONCEALED BODY ONLY FOR SHOWER MIXER 1 OUTLET",
        image: require("../../assets/BathroomMixers/FAF4010.webp"),
        image_2: require("../../assets/BathroomMixers/FAF4010_2.webp"),
    },
    {
        id: 9,
        name: "FAF402",
        description: "COVER PLATE WITH LEVER FOR CONCEALED SHOWER 2 OUTLETS WITH AUTOMATIC DIVERTER",
        image: require("../../assets/BathroomMixers/FAF402.webp"),
        image_2: require("../../assets/BathroomMixers/FAF402_2.webp"),
    },
    {
        id: 10,
        name: "FAF4021",
        description: "CONCEALED BODY ONLY FOR SHOWER MIXER 2 OUTLETS WITH AUTOMATIC DIVERTER. DZR BRASS",
        image: require("../../assets/BathroomMixers/FAF4021.webp"),
        image_2: require("../../assets/BathroomMixers/FAF4021_2.webp"),
    },
    {
        id: 11,
        name: "FAF1001",
        description: "BASIN MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF1001.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1001_2.webp"),
    },
    {
        id: 12,
        name: "FAF1002",
        description: "BIDET MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF1002.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1002_2.webp"),
    },
    {
        id: 13,
        name: "FAF1003",
        description: "BASIN MIXER W/O WASTE",
        image: require("../../assets/BathroomMixers/FAF1003.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1003_2.webp"),
    },
    {
        id: 14,
        name: "FAF1004",
        description: "WALL MOUNTED BATH/SHOWER MIXER WITH SHOWER KIT",
        image: require("../../assets/BathroomMixers/FAF1004.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1004_2.webp"),
    },
    {
        id: 15,
        name: "FAF1005",
        description: "EXPOSED SHOWER MIXER 1/2” OUTLET",
        image: require("../../assets/BathroomMixers/FAF1005.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1005_2.webp"),
    },
    {
        id: 16,
        name: "FAF1006",
        description: "COVER PLATE WITH LEVER FOR CONCEALED SHOWER 1 OUTLET",
        image: require("../../assets/BathroomMixers/FAF1006.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1006_2.webp"),
    },
    {
        id: 17,
        name: "FAF1007",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT",
        image: require("../../assets/BathroomMixers/FAF1007.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1007_2.webp"),
    },
    {
        id: 18,
        name: "FAF1008",
        description: "CONCEALED BODY ONLY FOR SHOWER MIXER 1 OUTLET",
        image: require("../../assets/BathroomMixers/FAF1008.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1008_2.webp"),
    },
    {
        id: 19,
        name: "FAF1010",
        description: "COVER PLATE WITH LEVER FOR CONCEALED SHOWER 2 OUTLETS WITH AUTOMATIC DIVERTER",
        image: require("../../assets/BathroomMixers/FAF1010.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1010_2.webp"),
    },
    {
        id: 20,
        name: "FAF1015",
        description: "PROFESSIONAL SINK MIXER WITH PULL-OUT HANDSPRAY",
        image: require("../../assets/BathroomMixers/FAF1015.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1015_2.webp"),
    },
    {
        id: 21,
        name: "FAF1011",
        description: "CONCEALED BODY ONLY FOR SHOWER MIXER 2 OUTLETS WITH AUTOMATIC DIVERTER. DZR BRASS",
        image: require("../../assets/BathroomMixers/FAF1011.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1011_2.webp"),
    },
    {
        id: 22,
        name: "FAF1020",
        description: "BASIN MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF1020.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1020_2.webp"),
    },
    {
        id: 23,
        name: "FAF1021",
        description: "BIDET MIXER WITH POP-UP WASTE",
        image: require("../../assets/BathroomMixers/FAF1021.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1021_2.webp"),
    },
    {
        id: 24,
        name: "FAF1022",
        description: "BASIN MIXER W/O WASTE",
        image: require("../../assets/BathroomMixers/FAF1022.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1022_2.webp"),
    },
    {
        id: 25,
        name: "FAF1023",
        description: "WALL MOUNTED BATH/SHOWER MIXER WITH SHOWER KIT",
        image: require("../../assets/BathroomMixers/FAF1023.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1023_2.webp"),
    },
    {
        id: 26,
        name: "FAF1030",
        description: "EXPOSED SHOWER MIXER 1/2” OUTLET",
        image: require("../../assets/BathroomMixers/FAF1030.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1030_2.webp"),
    },
    {
        id: 27,
        name: "FAF1031",
        description: "CONCEALED SHOWER WITH DIVERTER MIXER",
        image: require("../../assets/BathroomMixers/FAF1031.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1031_2.webp"),
    },
    {
        id: 28,
        name: "FAF1032",
        description: "CONCEALED SHOWER MIXER",
        image: require("../../assets/BathroomMixers/FAF1032.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1032_2.webp"),
    },
    {
        id: 29,
        name: "FAF1033",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT",
        image: require("../../assets/BathroomMixers/FAF1033.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1033_2.webp"),
    },
    {
        id: 30,
        name: "FAF1040",
        description: "No description",
        image: require("../../assets/BathroomMixers/FAF1040.webp"),
        image_2: require("../../assets/BathroomMixers/FAF1040_2.webp"),
    },
    {
        id: 31,
        name: "Porta Asciugamano",
        description: "No description",
        image: require("../../assets/BathroomMixers/Porta asciugamano.webp"),
    },
    {
        id: 32,
        name: "Porta Rotolo",
        description: "No description",
        image: require("../../assets/BathroomMixers/Porta rotolo.webp"),
    },
    {
        id: 33,
        name: "Porta Asciugamano Piccolo",
        description: "No description",
        image: require("../../assets/BathroomMixers/Porta asciugamano piccolo.webp"),
    },
    {
        id: 34,
        name: "Appoggio per Accappatoio",
        description: "No description",
        image: require("../../assets/BathroomMixers/Appoggio per accappatoio.webp"),
    },
]
