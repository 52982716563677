import React from 'react';
import ProductListGrid from "../../ProductListGrid";

const MethacrylateSinks = () => {

    const x = [
        {
            id: 1,
            name: 'Provenza',
            href: '/prodotti/provenza',
            imageSrc: require("../../../../assets/MethacrylateSinks/0861TDF.webp"),
            category: 'sinks'
        },
    ]

    return (
        <ProductListGrid products={x} title={"Our Methacrylate Sinks"}/>
    );
};

export default MethacrylateSinks;
