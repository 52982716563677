import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaNemoMix} from "../../../../../../data/sinks/granit-sinks";
import FeaturesSection from "../../../../../../components/FeaturesSection";
import AvailableColours from "../../../../../../components/prodotti/AvailableColours";
import colori from "../../../../../../assets/Granit/colori.png"

const GraniteNemoMix = () => {

    const {id} = useParams()

    const prodotto = listaNemoMix.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Base',
            description: prodotto?.base
        },
        {
            name: 'Bowl Depth',
            description: prodotto?.bowl_depth
        },
        {
            name: 'Built-In Fitting Hole',
            description: prodotto?.undertop_fitting_hole
        },
        {
            name: 'Standard Manual Waste Kit',
            description: prodotto?.standard_manual_waste_kit
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                    <AvailableColours colori={colori}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                    <img src={prodotto?.image_2!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default GraniteNemoMix;
