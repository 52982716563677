import {KitchenMixer} from "../interfaces";

export const listaKitchenMixer: KitchenMixer[] = [
    {
        id: 1,
        name: "FAF45041",
        description: "Sink mixer with pull out hand shower\n",
        color: "Not Available",
        design: "Not Available",
        image: require("../../assets/KitchenMixers/FAF45041.webp")
    },
    {
        id: 2,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45051",
        description: "SINK MIXER WITH PULL-OUT 2-JET HAND SHOWER",
        image: require("../../assets/KitchenMixers/FAF45051.webp")
    },
    {
        id: 3,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45081",
        description: "SINK MIXER WITH PULL-OUT 2 JET HAND SHOWER\n",
        image: require("../../assets/KitchenMixers/FAF45081.webp")
    },
    {
        id: 4,
        color: "Not Available",
        design: "Not Available",
        name: "FAF1011102",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF1011102.webp")
    },
    {
        id: 5,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45801",
        description: "SINK MIXER WITH PULL-OUT 2-JET HAND SHOWER\n\n",
        image: require("../../assets/KitchenMixers/FAF45801.webp")
    },
    {
        id: 6,
        color: "Not Available",
        design: "Not Available",
        name: "FAF115501",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF115501.webp")
    },
    {
        id: 7,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45501",
        description: "ONE-HOLE SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF45501.webp")
    },
    {
        id: 8,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45061",
        description: "ONE-HOLE SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF45061.webp")
    },
    {
        id: 9,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45211",
        description: "UNDER WINDOW SINK MIXER WITH INCLINABLE SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF45211.webp")
    },
    {
        id: 10,
        color: "Not Available",
        design: "Not Available",
        name: "FAF141301",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF141301.webp")
    },
    {
        id: 11,
        color: "Not Available",
        design: "Not Available",
        name: "FAF141211",
        description: "UNDER WINDOW SINK MIXER WITH INCLINABLE SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF141211.webp")
    },
    {
        id: 12,
        color: "Not Available",
        design: "Not Available",
        name: "FAF141101",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF141101.webp")
    },
    {
        id: 13,
        color: "Not Available",
        design: "Not Available",
        name: "FAF510101",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF510101.webp")
    },
    {
        id: 14,
        color: "Not Available",
        design: "Not Available",
        name: "FAF45901",
        description: "SINK MIXER WITH PULL-OUT HAND SHOWER\n",
        image: require("../../assets/KitchenMixers/FAF45901.webp")
    },
    {
        id: 15,
        color: "Not Available",
        design: "Not Available",
        name: "FAF111211",
        description: "SINGLE LEVER MIXER WITH SWIVEL SPOUT. ONE SPOUT WITH SEPARATE DISPENSERS. WITH PURIFIED WATER KIT\n",
        image: require("../../assets/KitchenMixers/FAF111211.webp")
    },
    {
        id: 16,
        color: "Not Available",
        design: "Not Available",
        name: "FAF111211",
        description: "SINGLE LEVER MIXER WITH SWIVEL SPOUT. ONE SPOUT WITH SEPARATE DISPENSERS. WITH PURIFIED WATER KIT\n",
        image: require("../../assets/KitchenMixers/FAF111211.webp")
    },
    {
        id: 17,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117311",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117311.webp")
    },
    {
        id: 18,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117321",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117321.webp")
    },
    {
        id: 19,
        color: "Not Available",
        design: "Not Available",
        name: "FAF141301",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF141301.webp")
    },
    {
        id: 20,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117331",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117331.webp")
    },
    {
        id: 21,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117201",
        description: "SINKMIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117201.webp")
    },
    {
        id: 22,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117401",
        description: "SINK MIXER WITH PULL-OUT 2-JET HAND SHOWER\n",
        image: require("../../assets/KitchenMixers/FAF117401.webp")
    },
    {
        id: 23,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117231",
        description: "SINK MIXER WITH SWIVEL SPOUT WITH DIVERTER\n",
        image: require("../../assets/KitchenMixers/FAF117231.webp")
    },
    {
        id: 24,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117231912",
        description: "SINK MIXER WITH SWIVEL SPOUT WITH DIVERTER TANK\n",
        image: require("../../assets/KitchenMixers/FAF117231912.webp")
    },
    {
        id: 25,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117101",
        description: "WALL MOUNTED SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117101.webp")
    },
    {
        id: 26,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117111",
        description: "WALL MOUNTED SINK MIXER WITH “S” SHORT SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117111.webp")
    },
    {
        id: 27,
        color: "Not Available",
        design: "Not Available",
        name: "FAF117121",
        description: "WALL MOUNTED SINK MIXER WITH “S” LONG SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF117121.webp")
    },
    {
        id: 28,
        color: "Not Available",
        design: "Not Available",
        name: "FAF31301",
        description: "SINK TAP WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF31301.webp")
    },
    {
        id: 29,
        color: "Not Available",
        design: "Not Available",
        name: "FAF31101",
        description: "WALL SINK MIXER WITH SWIVEL SPOUT\n",
        image: require("../../assets/KitchenMixers/FAF31101.webp")
    },
    {
        id: 30,
        color: "Not Available",
        design: "Not Available",
        name: "IMPUGNATURA",
        description: "No description",
        image: require("../../assets/KitchenMixers/IMPUGNATURA.webp")
    },
    {
        id: 31,
        color: "Not Available",
        design: "Not Available",
        name: "IMPUGNATURA 2",
        description: "No description",
        image: require("../../assets/KitchenMixers/IMPUGNATURA2.webp")
    },
    {
        id: 32,
        color: "Not Available",
        design: "Not Available",
        name: "LEVA",
        description: "No description",
        image: require("../../assets/KitchenMixers/LEVA 1.webp")
    },
    {
        id: 33,
        color: "Not Available",
        design: "Not Available",
        name: "LEVA",
        description: "No description",
        image: require("../../assets/KitchenMixers/LEVA 1.webp")
    },
    {
        id: 34,
        color: "Not Available",
        design: "Not Available",
        name: "LEVA 2",
        description: "No description",
        image: require("../../assets/KitchenMixers/LEVA 2.webp")
    },
    {
        id: 35,
        color: "Not Available",
        design: "Not Available",
        name: "MANIGLIA",
        description: "No description",
        image: require("../../assets/KitchenMixers/MANIGLIA 1.webp")
    },


]
