import React, {useEffect} from 'react';
import {listaElectricHobs} from "../../../../data/hobs/hobs-data";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const FocusHobs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaElectricHobs} name={"Our Electric hobs"}/>
    );
};

export default FocusHobs;
