import React, {useEffect} from 'react';
import ProductGrid from "../../../../components/prodotti/ProductGrid";
import {wallHoodsList} from "../../../../data/hoods/hoods-data";

const WallHoods = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={wallHoodsList} name={"Our Wall Hoods"}/>
    );
};

export default WallHoods;
