import React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import BaseLayout from "./layout/BaseLayout";
import Contatti from "./pages/Contatti";
import Prodotti from "./pages/prodotti/Prodotti";
import InoxHobs from "./pages/prodotti/hobs/inox-hobs/InoxHobs";
import AtecHobs from "./pages/prodotti/hobs/atec-hobs/AtecHobs";
import FocusHobs from "./pages/prodotti/hobs/focus-hobs/FocusHobs";
import AtecHob from "./pages/prodotti/hobs/atec-hobs/[id]/AtecHob";
import FocusHob from "./pages/prodotti/hobs/focus-hobs/[id]/FocusHob";
import InoxHob from "./pages/prodotti/hobs/inox-hobs/[id]/InoxHob";
import About from "./pages/About";
import ElectricHobs from "./pages/prodotti/hobs/electric-hobs/ElectricHobs";
import ElectricHob from "./pages/prodotti/hobs/electric-hobs/[id]/ElectricHob";
import Ovens from "./pages/prodotti/ovens/Ovens";
import Oven from "./pages/prodotti/ovens/[id]/Oven";
import FocusLineOvens from "./pages/prodotti/ovens/focus-line-ovens/FocusLineOvens";
import FocusLineOven from "./pages/prodotti/ovens/focus-line-ovens/[id]/FocusLineOven";
import KitchenMixers from "./pages/prodotti/mixers/kitchen-mixers/KitchenMixers";
import KitchenMixer from "./pages/prodotti/mixers/kitchen-mixers/[id]/KitchenMixer";
import BathroomMixers from "./pages/prodotti/mixers/bathroom-mixers/BathroomMixers";
import BathroomMixer from "./pages/prodotti/mixers/bathroom-mixers/[id]/BathroomMixer";
import GranitNemos from "./pages/prodotti/sinks/granit/nemo/GranitNemos";
import GranitNemo from "./pages/prodotti/sinks/granit/nemo/[id]/GranitNemo";
import GranitNemosMix from "./pages/prodotti/sinks/granit/nemo-mix/GranitNemosMix";
import GranitNemoMix from "./pages/prodotti/sinks/granit/nemo-mix/[id]/GranitNemoMix";
import InoxSinks from "./pages/prodotti/sinks/inox-sink/InoxSinks";
import InoxSink from "./pages/prodotti/sinks/inox-sink/[id]/InoxSink";
import GranitOrlandos from "./pages/prodotti/sinks/granit/orlando/GranitOrlandos";
import GranitOrlando from "./pages/prodotti/sinks/granit/orlando/[id]/GranitOrlando";
import GranitRomeos from "./pages/prodotti/sinks/granit/romeo/GranitRomeos";
import GranitRomeo from "./pages/prodotti/sinks/granit/romeo/[id]/GranitRomeo";
import HobsPage from "./pages/prodotti/hobs/HobsPage";
import OvensPage from "./pages/prodotti/ovens/OvensPage";
import MixersPage from "./pages/prodotti/mixers/MixersPage";
import SinksPage from "./pages/prodotti/sinks/SinksPage";
import Page404 from "./pages/Page404";
import Catalogo from "./pages/Catalogo";
import WallHoods from "./pages/prodotti/hoods/wall/WallHoods";
import WallHood from "./pages/prodotti/hoods/wall/[id]/WallHood";
import HoodsPage from "./pages/prodotti/hoods/HoodsPage";
import IslandHoods from "./pages/prodotti/hoods/island/IslandHoods";
import IslandHood from "./pages/prodotti/hoods/island/[id]/IslandHood";
import BuiltInHoods from "./pages/prodotti/hoods/built-in/BuiltInHoods";
import BuiltInHood from "./pages/prodotti/hoods/built-in/[id]/BuiltInHood";
import Cellars from "./pages/prodotti/cellars/dimension/Cellars";
import DimensionCellarGird from "./pages/prodotti/cellars/dimension/DimensionCellarGird";
import Cellar from "./pages/prodotti/cellars/dimension/[id]/Cellar";
import GranitSinks from "./pages/prodotti/sinks/granit/GranitSinks";
import MethacrylateSinks from "./pages/prodotti/sinks/methacrylate/MethacrylateSinks";
import ProvenzaSinks from "./pages/prodotti/sinks/methacrylate/provenza/ProvenzaSinks";
import ProvenzaSink from "./pages/prodotti/sinks/methacrylate/provenza/[id]/ProvenzaSink";

interface IRoute {
    path: string;
    element: React.ReactNode;
}

function App() {

    const routeList: IRoute[] = [
        // BASE ROUTES
        {path: "/", element: <Home/>},
        {path: "/contatti", element: <Contatti/>},
        {path: "/about", element: <About/>},
        {path: "/prodotti", element: <Prodotti/>},
        {path: "/catalogo", element: <Catalogo/>},
        {path: "*", element: <Page404/>},

        // PRODOTTI ROUTES
        {path: "/prodotti/hobs", element: <HobsPage/>},
        {path: "/prodotti/ovens-page", element: <OvensPage/>},
        {path: "/prodotti/mixers", element: <MixersPage/>},
        {path: "/prodotti/sinks", element: <SinksPage/>},

        // INOX HOBS ROUTES
        {path: "/prodotti/inox-hobs", element: <InoxHobs/>},
        {path: "/prodotti/inox-hobs/:id", element: <InoxHob/>},

        // ATEC HOBS ROUTES
        {path: "/prodotti/atec-hobs", element: <AtecHobs/>},
        {path: "/prodotti/atec-hobs/:id", element: <AtecHob/>},

        // FOCUS HOBS ROUTES
        {path: "/prodotti/focus-hobs", element: <FocusHobs/>},
        {path: "/prodotti/focus-hobs/:id", element: <FocusHob/>},

        // ELECTRIC HOBS ROUTES
        {path: "/prodotti/electric-hobs", element: <ElectricHobs/>},
        {path: "/prodotti/electric-hobs/:id", element: <ElectricHob/>},

        // OVENS ROUTES
        {path: "/prodotti/ovens", element: <Ovens/>},
        {path: "/prodotti/ovens/:id", element: <Oven/>},
        {path: "/prodotti/focus-ovens", element: <FocusLineOvens/>},
        {path: "/prodotti/focus-ovens/:id", element: <FocusLineOven/>},

        // MIXERS ROUTES
        {path: "/prodotti/kitchen-mixers", element: <KitchenMixers/>},
        {path: "/prodotti/kitchen-mixers/:id", element: <KitchenMixer/>},
        {path: "/prodotti/bathroom-mixers", element: <BathroomMixers/>},
        {path: "/prodotti/bathroom-mixers/:id", element: <BathroomMixer/>},

        // SINKS ROUTES
        {path: "/prodotti/granit-sinks", element: <GranitSinks/>},
        {path: "/prodotti/granit-nemo", element: <GranitNemos/>},
        {path: "/prodotti/granit-nemo/:id", element: <GranitNemo/>},
        {path: "/prodotti/granit-nemo-mix", element: <GranitNemosMix/>},
        {path: "/prodotti/granit-nemo-mix/:id", element: <GranitNemoMix/>},
        {path: "/prodotti/granit-orlando", element: <GranitOrlandos/>},
        {path: "/prodotti/granit-orlando/:id", element: <GranitOrlando/>},
        {path: "/prodotti/granit-romeo", element: <GranitRomeos/>},
        {path: "/prodotti/granit-romeo/:id", element: <GranitRomeo/>},
        {path: "/prodotti/methacrylate-sinks", element: <MethacrylateSinks/>},
        {path: "/prodotti/provenza", element: <ProvenzaSinks/>},
        {path: "/prodotti/provenza/:id", element: <ProvenzaSink/>},

        // INOX SINKS ROUTES
        {path: "/prodotti/inox-sinks", element: <InoxSinks/>},
        {path: "/prodotti/inox-sinks/:id", element: <InoxSink/>},

        // HOODS
        {path: "/prodotti/hoods", element: <HoodsPage/>},

        // WALL HOODS
        {path: "/prodotti/wall-hoods", element: <WallHoods/>},
        {path: "/prodotti/wall-hoods/:id", element: <WallHood/>},
        // ISLAND HOODS
        {path: "/prodotti/island-hoods", element: <IslandHoods/>},
        {path: "/prodotti/island-hoods/:id", element: <IslandHood/>},
        // ISLAND HOODS
        {path: "/prodotti/builtin-hoods", element: <BuiltInHoods/>},
        {path: "/prodotti/builtin-hoods/:id", element: <BuiltInHood/>},

        // CELLARS
        {path: "/prodotti/Cellars", element: <DimensionCellarGird/>},
        {path: "/prodotti/Cellars/:tag", element: <Cellars/>},
        {path: "/prodotti/Cellars/:tag/:id", element: <Cellar/>},
    ]

    return (
        <HashRouter>
            <Routes>
                {
                    routeList.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <BaseLayout>
                                    {route.element}
                                </BaseLayout>
                            }
                        />
                    ))
                }
            </Routes>
        </HashRouter>
    );
}

export default App;
