import React from 'react';

interface FeaturesSectionProps {
    features: Feature[]
}
const FeaturesSection = ({features}: FeaturesSectionProps) => {
    return (
        <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 lg:gap-x-8">
            {
                features.map((feature, i) => (
                    <div key={i} className="border-t border-gray-200 pt-4">
                        <div className="font-semibold text-gray-900">{feature.name}</div>
                        <div className="mt-2 text-sm text-gray-500">{feature.description}</div>
                    </div>
                ))
            }
        </div>
    );
};

export default FeaturesSection;
