import React, {useEffect} from 'react';
import {listaKitchenMixer} from "../../../../data/mixers/kitchen-mixers";
import ProductGrid from "../../../../components/prodotti/ProductGrid";
import {NewKitchenMixersList} from "../../../../data/mixers/new-kitchen-mixers";

const KitchenMixers = () => {

    const newList = listaKitchenMixer.concat(NewKitchenMixersList)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={newList} name={"Our Kitchen Mixers"}/>
    );
};

export default KitchenMixers;
