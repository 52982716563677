import React from 'react';
import {IProduct} from "../../data/interfaces";
import {Link} from "react-router-dom";

interface ProductGridProps {
    prodotti: IProduct[]
    name: string
}

const ProductGrid = ({prodotti, name}: ProductGridProps) => {

    const [filter, setFilter] = React.useState("");

    const [filteredProducts, setFilteredProducts] = React.useState(prodotti);

    React.useEffect(() => {
        setFilteredProducts(prodotti.filter(
            (product) => product.name.toLowerCase().includes(filter.toLowerCase())
        ))
    }, [filter, prodotti])

    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl py-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                        {name}
                    </h2>

                    <div className={"w-full mt-3"}>
                        <input
                            type="text"
                            placeholder={"Cerca"}
                            onChange={e => setFilter(e.target.value)}
                            className={"w-full p-2 rounded-md border-none outline-none bg-zinc-50 border-transparent focus:border-transparent focus:ring-0"}
                        />
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        {
                            filteredProducts.length !== 0 ? (
                                filteredProducts.map((product) => (
                                    <div key={product.id} className="group relative">
                                        <div
                                            className="min-h-80 aspect-w-10 aspect-h-8 w-full overflow-hidden rounded-md border group-hover:opacity-75">
                                            <img
                                                src={product.image!}
                                                alt={""}
                                                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                            />
                                        </div>
                                        <div className="mt-4 flex justify-between">
                                            <div>
                                                <h3 className="text-sm text-gray-700">
                                                    <Link to={`${product.id}`}>
                                                        <span aria-hidden="true" className="absolute inset-0"/>
                                                        {product.name}
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={"col-span-4"}>
                                    <h3 className={"text-2xl font-bold text-gray-900"}>Nessun prodotto trovato per
                                        - {filter}</h3>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductGrid;
