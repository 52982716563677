import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import FeaturesSection from "../../../../../components/FeaturesSection";
import {cellarsData} from "../../../../../data/cellars/cellars-data";

const Cellar = () => {
    const {id} = useParams()

    const prodotto = cellarsData.find((prodotto) => prodotto.id === parseInt(id as string))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const features: Feature[] = [
        {
            name: "Description",
            description: prodotto?.description
        },
        {
            name: "Zones",
            description: prodotto?.zones
        },
        {
            name: "Temperature",
            description: prodotto?.temperature
        },
        {
            name: "Energy Class",
            description: prodotto?.energy_class
        },
        {
            name: "Capacity",
            description: prodotto?.capacity
        },
        {
            name: "Control",
            description: prodotto?.control
        },
        {
            name: "Shelves",
            description: prodotto?.shelves
        },
        {
            name: "Reversible Door",
            description: prodotto?.reversible_door
        },
        {
            name: "Frame",
            description: prodotto?.frame
        },
        {
            name: "Door",
            description: prodotto?.door
        },
        {
            name: "Dimensions",
            description: prodotto?.dimensions
        },
        {
            name: "Handle",
            description: prodotto?.handle
        },
        {
            name: "Light",
            description: prodotto?.light
        },
        {
            name: "Humidity Control",
            description: prodotto?.humidity_control
        },
        {
            name: "Anti Vibration System",
            description: prodotto?.anti_vibration_system
        },
        {
            name: "Door Alarm",
            description: prodotto?.door_alarm
        },
        {
            name: "Temperature Alarm",
            description: prodotto?.temperature_alarm
        },
        {
            name: "Consumption",
            description: prodotto?.consumption
        },
        {
            name: "Adjustable Feet",
            description: prodotto?.adjustable_feet
        },
        {
            name: "Sound Level",
            description: prodotto?.sound_level
        },
        {
            name: "Sound Class",
            description: prodotto?.sound_class
        },
        {
            name: "Ventilation System",
            description: prodotto?.ventilation_system
        },
        {
            name: "Usable Volume",
            description: prodotto?.usable_volume
        },
        {
            name: "Climate Class",
            description: prodotto?.climate_class
        },
        {
            name: "Net Weight",
            description: prodotto?.net_weight
        },
    ]

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                    <img src={prodotto?.image_2!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default Cellar;
