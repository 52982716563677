import {MethacrylateInterface} from "../interfaces";

export const listaMethacrylate: MethacrylateInterface[] = [
    {
        id: 1,
        name: "116TDF",
        misure: "116x50cm",
        image: require("../../assets/MethacrylateSinks/116TDF.webp"),
        image_2: require("../../assets/MethacrylateSinks/116TDF_DT.webp"),
        image_3: require("../../assets/MethacrylateSinks/02_1162TDFOBR_DisegnoTecnico.webp"),
    },
    {
        id: 2,
        name: "0861TDF V1",
        misure: "86x50cm",
        image: require("../../assets/MethacrylateSinks/0861TDF.webp"),
        image_2: require("../../assets/MethacrylateSinks/0861TDF_DT.webp"),
        image_3: require("../../assets/MethacrylateSinks/02_0861TDF_DisegnoTecnico.webp"),
    },
    {
        id: 3,
        name: "0862TDF V2",
        misure: "86x50cm",
        image: require("../../assets/MethacrylateSinks/0862TDF.webp"),
        image_2: require("../../assets/MethacrylateSinks/0862TDF_DT.webp"),
        image_3: require("../../assets/MethacrylateSinks/02_0862TDF_DisegnoTecnico.webp"),
    }
]
