import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {builtInHoodsList} from "../../../../../data/hoods/hoods-data";
import FeaturesSection from "../../../../../components/FeaturesSection";

const IslandHood = () => {
    const {id} = useParams()

    const prodotto = builtInHoodsList.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Dimensions',
            description: prodotto?.dimensions
        },
        {
            name: 'Finishing',
            description: prodotto?.finishing
        },
        {
            name: 'Version',
            description: prodotto?.version
        },
        {
            name: 'Controls',
            description: prodotto?.controls
        },
        {
            name: 'Lights',
            description: prodotto?.lights
        },
        {
            name: 'Air Flow',
            description: prodotto?.air_flow
        },
        {
            name: 'Pressure',
            description: prodotto?.pressure
        },
        {
            name: 'Power',
            description: prodotto?.power
        },
        {
            name: 'Noise Level',
            description: prodotto?.noise_level
        },
        {
            name: 'Accessories',
            description: prodotto?.accessories
        },
        {
            name: 'Energy Class',
            description: prodotto?.energy_class
        },
        {
            name: 'Chimneys',
            description: prodotto?.chimneys
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white">
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                    {
                        prodotto?.image === prodotto?.image_2 ? null : <img src={prodotto?.image_2!} alt={""}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default IslandHood;
