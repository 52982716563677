import React, {useEffect} from 'react';
import {listaNemo} from "../../../../../data/sinks/granit-sinks";
import ProductGrid from "../../../../../components/prodotti/ProductGrid";

const GranitNemosPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ProductGrid prodotti={listaNemo} name={"Our Granit Nemo"}/>
        </>
    );
};

export default GranitNemosPage;
