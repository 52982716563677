import {BuildingOffice2Icon, EnvelopeIcon, PhoneIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from "react";
import DisplayAlert from "../components/alerts/DisplayAlert";
import emailjs from '@emailjs/browser';

interface EmailInterface {
    nome: string,
    cognome: string,
    email: string,
    telefono: string,
    messaggio: string
}

const Contatti = () => {

    const [message, setMessage] = useState<AlertMessage>({
        message: "",
        show: false,
        positive: false
    });

    const defaultEmail: EmailInterface = {
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        messaggio: ""
    }

    const [email, setEmail] = useState<EmailInterface>(defaultEmail)

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setEmail({...email, [name]: value});
    }

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_k4edzun', 'template_0j36cfn', e.currentTarget, 'OME1dEqpR2qg18MLR')
            .then(() => {
                setMessage({
                    message: "We have received your request, we will reply as soon as possible",
                    show: true,
                    positive: true
                })
                setEmail(defaultEmail)
            }, () => {
                setMessage({
                    message: "There was an error sending your request, please try again",
                    show: true,
                    positive: false
                })
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                {/*INFO*/}
                <div className="relative px-6 lg:static py-10 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div
                            className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none"/>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="white"/>
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
                                </svg>
                                <rect width="100%" height="100%" strokeWidth={0}
                                      fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"/>
                            </svg>
                        </div>
                        <div className={"animate-fade-right"}>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Contattaci</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Se sei interessato a contattarci per un preventivo, per un consiglio o per qualsiasi
                                altra
                                cosa, non esitare a
                                scriverci.
                            </p>
                            <div className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                                {/*Sede*/}
                                <div className="flex gap-x-4">
                                    <div className="flex-none">
                                        <span className="sr-only">Sede</span>
                                        <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <span className={"font-semibold"}>Sede Legale: </span> Via Umberto I. 62, 04100
                                        Latina
                                    </div>
                                </div>
                                <div className="flex gap-x-4">
                                    <div className="flex-none">
                                        <span className="sr-only">Sede</span>
                                        <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <span className={"font-semibold"}>Sede Operativa: </span> Via dell'artigianato
                                        175,
                                        04100 Roccagorga (LT)
                                    </div>
                                </div>
                                {/*Telefono*/}
                                <div className="flex gap-x-4">
                                    <div className="flex-none">
                                        <span className="sr-only">Telefono</span>
                                        <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <a className="hover:text-gray-900" href="tel:07731710907">
                                            0773 1710 907
                                        </a>
                                    </div>
                                </div>
                                {/*Email*/}
                                <div className="flex gap-x-4">
                                    <div className="flex-none">
                                        <span className="sr-only">Email</span>
                                        <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <a className="hover:text-gray-900" href="mailto:info@fafcompany.com">
                                            info@fafcompany.com
                                        </a>
                                    </div>
                                </div>
                                <div className="flex gap-x-4">
                                    <div className="flex-none">
                                        <span className="sr-only">Email</span>
                                        <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <div>
                                        <a className="hover:text-gray-900" href="mailto:secretary@fafcompany.com">
                                            secretary@fafcompany.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*FORM*/}
                <form onSubmit={sendEmail} id={"contact-form"}
                      className={`px-6 lg:static py-10 sm:py-32 lg:px-8 animate-fade-left`}>
                    <div className={"mx-auto max-w-xl lg:mr-0 lg:max-w-lg"}>
                        <DisplayAlert message={message} handleClose={setMessage}/>
                    </div>
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                            <div>
                                <label htmlFor="nome" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Nome
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="nome"
                                        autoComplete="given-name"
                                        onChange={onChange}
                                        value={email.nome}
                                        className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="cognome"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Cognome
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="cognome"
                                        autoComplete="family-name"
                                        onChange={onChange}
                                        value={email.cognome}
                                        className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        onChange={onChange}
                                        value={email.email}
                                        className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="telefono"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Numero di telefono
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="tel"
                                        name="telefono"
                                        autoComplete="tel"
                                        onChange={onChange}
                                        value={email.telefono}
                                        className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="messaggio"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Messaggio
                                </label>
                                <div className="mt-2.5">
                                      <textarea
                                          name="messaggio"
                                          onChange={onChange}
                                          value={email.messaggio}
                                          className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          required
                                      />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Invia Messaggio
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contatti;
