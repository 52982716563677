import {ProductInterface} from "./interfaces";


export const products: ProductInterface[] = [
    {
        id: 1,
        name: 'Inox Hobs',
        href: '/prodotti/inox-hobs',
        imageSrc: require("../assets/InoxHobs/AH7007ST1_2.webp"),
        category: 'hobs'
    },
    {
        id: 2,
        name: 'Inox Hobs - A-Tec',
        href: '/prodotti/atec-hobs',
        imageSrc: require("../assets/A-Tec/FAF604B4.webp"),
        category: 'hobs'
    },
    {
        id: 3,
        name: 'Inox Hobs - Focus-Line',
        href: '/prodotti/focus-hobs',
        imageSrc: require("../assets/Focus-Line/Hobs/F402AN.webp"),
        category: 'hobs'
    },
    {
        id: 4,
        name: 'Electric Hobs',
        href: '/prodotti/electric-hobs',
        imageSrc: require("../assets/ElectricHobs/90CM_2.webp"),
        category: 'hobs'
    },
    {
        id: 5,
        name: 'Ovens',
        href: '/prodotti/ovens',
        imageSrc: require("../assets/Ovens/ABO6009ST1.webp"),
        category: 'ovens'
    },
    {
        id: 6,
        name: 'Focus Line Ovens',
        href: '/prodotti/focus-ovens',
        imageSrc: require("../assets/Focus-Line/Ovens/F522 Inox (Acciaio).webp"),
        category: 'ovens'
    },
    {
        id: 7,
        name: 'Kitchen Mixers',
        href: '/prodotti/kitchen-mixers',
        imageSrc: require("../assets/KitchenMixers/main.webp"),
        category: 'mixers'
    },
    {
        id: 8,
        name: 'Bathroom Mixers',
        href: '/prodotti/bathroom-mixers',
        imageSrc: require("../assets/BathroomMixers/mainBathroomMixer.webp"),
        category: 'mixers'
    },
    {
        id: 90,
        name: 'Granit Sinks',
        href: '/prodotti/granit-sinks',
        imageSrc: require("../assets/Granit/Nemo/nemo.webp"),
        category: 'sinks'
    },
    {
        id: 91,
        name: 'Inox Sinks',
        href: '/prodotti/inox-sinks',
        imageSrc: require("../assets/InoxSink/LavelliInAcciaio.webp"),
        category: 'sinks'
    },
    {
        id: 91,
        name: 'Methacrylate Sinks',
        href: '/prodotti/methacrylate-sinks',
        imageSrc: require("../assets/MethacrylateSinks/0862TDF.webp"),
        category: 'sinks'
    },
    {
        id: 14,
        name: 'Wall Hoods',
        href: '/prodotti/wall-hoods',
        imageSrc: require("../assets/Hoods/Wall/HoodsCover.webp"),
        category: 'hoods'
    },
    {
        id: 15,
        name: 'Island Hoods',
        href: '/prodotti/island-hoods',
        imageSrc: require("../assets/Hoods/Wall/HoodsCover.webp"),
        category: 'hoods'
    },
    {
        id: 16,
        name: 'Built-In Hoods',
        href: '/prodotti/builtin-hoods',
        imageSrc: require("../assets/Hoods/Wall/HoodsCover.webp"),
        category: 'hoods'
    },
]
