import React, {useEffect} from 'react';
import ProductListGrid from "../ProductListGrid";
import {ProductInterface} from "../../../data/interfaces";

const HobsPage = () => {

    const x: ProductInterface[] = [
        {
            id: 1,
            name: 'Atec Italy',
            href: '/prodotti/atec-hobs',
            imageSrc: require("../../../assets/loghi/atec-italy-logo.webp"),
            category: 'hobs'
        },
        {
            id: 2,
            name: 'Atec',
            href: '/prodotti/atec-hobs',
            imageSrc: require("../../../assets/loghi/atec-logo.webp"),
            category: 'hobs'
        },
        {
            id: 3,
            name: 'Focus',
            href: '/prodotti/focus-hobs',
            imageSrc: require("../../../assets/loghi/focus-logo.webp"),
            category: 'hobs'
        },
        {
            id: 4,
            name: 'Inox Hobs',
            href: '/prodotti/inox-hobs',
            imageSrc: require("../../../assets/InoxHobs/AH7007ST1_2.webp"),
            category: 'hobs'
        },
        {
            id: 5,
            name: 'Electric Hobs',
            href: '/prodotti/electric-hobs',
            imageSrc: require("../../../assets/ElectricHobs/90CM_2.webp"),
            category: 'hobs'
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductListGrid
            title={"Our Brands"}
            products={x}
            scaled={true}
        />
    )
};

export default HobsPage;
