import React, {useEffect} from 'react';
import {listaAtecHobs} from "../../../../data/hobs/hobs-data";
import ProductGrid from "../../../../components/prodotti/ProductGrid";

const AtecHobs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaAtecHobs} name={"Our Atec hobs"}/>
    );
};

export default AtecHobs;
