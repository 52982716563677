import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {listaAtecHobs} from "../../../../../data/hobs/hobs-data";
import FeaturesSection from "../../../../../components/FeaturesSection";

const ProdottoAtec = () => {

    const {id} = useParams()

    const prodotto = listaAtecHobs.find((prodotto) => prodotto.id === parseInt(id as string))

    const features: Feature[] = [
        {
            name: 'Item',
            description: prodotto?.item
        },
        {
            name: 'Panel',
            description: prodotto?.panel
        },
        {
            name: 'Pan Supports',
            description: prodotto?.pan_supports
        },
        {
            name: 'Power in KW',
            description: prodotto?.power
        },
        {
            name: 'Burner Cap',
            description: prodotto?.burner_cap
        },
        {
            name: 'Pan Supports',
            description: prodotto?.pan_supports
        },
        {
            name: 'Knobs',
            description: prodotto?.knobs
        },
        {
            name: 'Ignition',
            description: prodotto?.ignition
        },
        {
            name: 'Safety Device',
            description: prodotto?.safety_device ? "Yes" : "No"
        },
        {
            name: "Product Size",
            description: prodotto?.product_size
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="bg-white">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-y-16 gap-x-8 py-24 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{prodotto?.name}</h2>

                    <FeaturesSection features={features}/>
                </div>
                <div className={"flex flex-col items-center gap-8"}>
                    <img src={prodotto?.image!} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default ProdottoAtec;
