import React, {useEffect} from 'react';
import {listaOvens} from "../../../data/ovens/oven-data";
import ProductGrid from "../../../components/prodotti/ProductGrid";

const Ovens = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProductGrid prodotti={listaOvens} name={"Out Ovens"}/>
    );
};

export default Ovens;
