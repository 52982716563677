import React from 'react';
import {Link} from "react-router-dom";
import {ProductInterface} from "../../data/interfaces";

interface Props {
    title: string;
    products: ProductInterface[]
    scaled?: boolean;
}

const ProductListGrid = ({title, products, scaled}: Props) => {

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className={"text-4xl font-semibold"}>
                    {title}
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                    {
                        products.map((product) => (
                            <div key={product.id} className="group relative">
                                <div
                                    className={`aspect-w-4 aspect-h-3 overflow-hidden rounded-lg ${scaled ? "border" : ""}`}>
                                    <img src={product.imageSrc} alt={""}
                                         className={`object-center ${scaled ? "scale-90 object-contain" : "object-cover"}`}/>
                                    <div className="flex items-end p-4 opacity-0 group-hover:opacity-100"
                                         aria-hidden="true">
                                        <div
                                            className="w-full rounded-md bg-white bg-opacity-75 py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
                                            Visualizza Prodotti
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
                                    <h3>
                                        <Link to={product.href}>
                                            <span aria-hidden="true" className="absolute inset-0"/>
                                            {product.name}
                                        </Link>
                                    </h3>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductListGrid;
