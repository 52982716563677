import {FocusLineOven, Oven} from "../interfaces";

export const listaOvens: Oven[] = [
    {
        id: 1,
        name: "ABO600ST1-GAS/GAS STATIC",
        platform: "60x60 cm",
        fan: "No",
        cavity: "64 liters",
        energy_class: "A",
        functions: "1",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Soft closing",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "3 Metal Knobs",
        door_aestetic: "Bottom inox Stripe",
        programmer: "Knobs",
        image: require("../../assets/Ovens/ABO600ST1_GAS_GAS STATIC.webp")
    },
    {
        id: 2,
        name: "FM60SG03M3X-GAS/GAS STATIC",
        platform: "60x60 cm",
        fan: "1",
        cavity: "64 liters",
        energy_class: "A",
        functions: "3",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Soft closing",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "3 Metal Knobs",
        door_aestetic: "Bottom inox Stripe",
        programmer: "Knobs",
        image: require("../../assets/Ovens/FM60SG03M3X.webp")
    },
    {
        id: 3,
        name: "ABO6001ST1 - GAS",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "4",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel",
        door_aestetic: "Bottom inox Stripe",
        programmer: "3 Knobs",
        image: require("../../assets/Ovens/ABO6001ST1_GAG_GAS STATIC.webp")
    },
    {
        id: 4,
        name: "ABO6001ST1 - GAS/GAS STATIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "4",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel",
        door_aestetic: "Bottom inox Stripe",
        programmer: "3 Knobs",
        image: require("../../assets/Ovens/ABO6001ST1_GAG_GAS STATIC.webp")
    },
    {
        id: 5,
        name: "ABO6002BK1 - GAS",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "4",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel",
        door_aestetic: "Bottom inox Stripe",
        programmer: "3 Knobs",
        image: require("../../assets/Ovens/ABO6002BK1.webp")
    },
    {
        id: 6,
        name: "FM90SE11T - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "11",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "No",
        door_aestetic: "Slim bottom frame",
        programmer: "Full touch",
        image: require("../../assets/Ovens/FM90SE11T.webp")
    },
    {
        id: 7,
        name: "FM90KE11T - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "11",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "No",
        door_aestetic: "Black crystal",
        programmer: "Full touch",
        image: require("../../assets/Ovens/FM90KE11T.webp")
    },
    {
        id: 8,
        name: "FM90SE06M - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "6",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Slim bottom frame",
        programmer: "3 Knobs",
        image: require("../../assets/Ovens/FM90SE06M.webp")
    },
    {
        id: 9,
        name: "FM90KE06M - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "6",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Black Crystal",
        programmer: "3 Knobs",
        image: require("../../assets/Ovens/FM90KE06M.webp")
    },
    {
        id: 10,
        name: "ABO6009ST1 - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "8",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Slim bottom frame",
        programmer: "2 knobs control + digital timer",
        image: require("../../assets/Ovens/ABO6009ST1.webp")
    },
    {
        id: 11,
        name: "ABO6010BK1 - ELECTRIC",
        platform: "90x60 cm",
        fan: "1",
        cavity: "120 liters",
        energy_class: "A",
        functions: "8",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Black Crystal",
        programmer: "2 knobs control + digital timer",
        image: require("../../assets/Ovens/ABO6010BK1.webp")
    },
    {
        id: 12,
        name: "FM98XE08D - ELECTRIC",
        platform: "90x48 cm",
        fan: "1",
        cavity: "100 liters",
        energy_class: "A",
        functions: "6",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Black Crystal",
        programmer: "2 knobs control + digital timer",
        image: require("../../assets/Ovens/FM98XE08D.webp")
    },
    {
        id: 13,
        name: "FM98KE08D - ELECTRIC",
        platform: "90x48 cm",
        fan: "1",
        cavity: "100 liters",
        energy_class: "A",
        functions: "6",
        chromed_side_racks: "Yes",
        tray: "1 (25mm)",
        grid: "1",
        door_closing: "Standard",
        door_glass: "Double glass",
        cavity_enamel: "Black",
        internal_lamp: "Rounded",
        handle: "H28 (Standard)",
        knobs: "Nickel Knobs",
        door_aestetic: "Black Crystal",
        programmer: "2 knobs control + digital timer",
        image: require("../../assets/Ovens/FM98KE08D.webp")
    },
]


export const listaFocusLineOvens: FocusLineOven[] = [
    {
        id: 1,
        name: "F526IV /3 Oat",
        type: "Electric Built-in Oven (Rustic line)",
        color: "Oat (Avena)",
        functions: "Non disponibile",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "59,80 x 59,50 x 56",
        timer: "Mechanical",
        knobs: "Rustic style metal knobs",
        door: "Oat painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F526IV 3 (Oat).webp")
    },
    {
        id: 2,
        name: "F526AN /3 Anthracite",
        type: "Electric Built-in Oven (Rustic line)",
        color: "Anthracite",
        functions: "Non disponibile",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "59,80 x 59,50 x 56",
        timer: "Mechanical",
        knobs: "Rustic style metal knobs",
        door: "Anthracite painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F526AN 3 (Anthracite).webp")
    },
    {
        id: 3,
        name: "F525IV",
        type: "Electric Built-in Oven (Modern line)",
        color: "Oat",
        functions: "6",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 54",
        timer: "Mechanical",
        knobs: "Metal knobs",
        door: "Oat painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F525IV (Oat- Avena).webp")
    },
    {
        id: 4,
        name: "F525AN",
        type: "Electric Built-in Oven (Modern line)",
        color: "Anthracite",
        functions: "6",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 54",
        timer: "Mechanical",
        knobs: "Metal knobs",
        door: "Anthracite painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F525AN (Anthracite).webp")
    },
    {
        id: 5,
        name: "F525 Inox",
        type: "Electric Built-in Oven (Modern line)",
        color: "Inox",
        functions: "6",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 54",
        timer: "Mechanical",
        knobs: "Metal knobs",
        door: "Black painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F525 Inox (Acciaio).webp")
    },
    {
        id: 6,
        name: "F525 Black",
        type: "Electric Built-in Oven (Modern line)",
        color: "Black",
        functions: "6",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 54",
        timer: "Mechanical",
        knobs: "Metal knobs",
        door: "Black painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F525 Black (Nero).webp")
    },
    {
        id: 7,
        name: "F522IV (Oat/Avena)",
        type: "Electric Built-in Oven (Modern line)",
        color: "Oat",
        functions: "9",
        energy_class: "A",
        capacity: "69 Liters",
        dimension: "60 x 60 x 60",
        timer: "Electronic/Mechanical",
        knobs: "Metal knobs (2)",
        door: "Oat painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F522IV (OatAvena).webp")
    },
    {
        id: 8,
        name: "F522AN Anthracite",
        type: "Electric Built-in Oven (Modern line)",
        color: "Anthracite",
        functions: "9",
        energy_class: "A",
        capacity: "69 Liters",
        dimension: "60 x 60 x 60",
        timer: "Electronic/Mechanical",
        knobs: "Metal knobs (2)",
        door: "Oat painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F522AN (Anthracite).webp")
    },
    {
        id: 9,
        name: "F522 Inox (Acciaio)",
        type: "Electric Built-in Oven (Modern line)",
        color: "Inox",
        functions: "9",
        energy_class: "A",
        capacity: "69 Liters",
        dimension: "60 x 60 x 60",
        timer: "Electronic/Mechanical",
        knobs: "Metal knobs (2)",
        door: "Oat painted glass",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F522 Inox (Acciaio).webp")
    },
    {
        id: 10,
        name: "F526IV",
        type: "Electric Built-in Oven (Rustic line)",
        color: "Oat",
        functions: "8",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 60",
        timer: "Rustic style analogue electrical timer",
        knobs: "Metal knobs (2)",
        door: "Double glazed oven door",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F526IV.webp")
    },
    {
        id: 11,
        name: "F526AN",
        type: "Electric Built-in Oven (Rustic line)",
        color: "Anthracite",
        functions: "8",
        energy_class: "A",
        capacity: "67 Liters Easy Clean",
        dimension: "60 x 60 x 60",
        timer: "Rustic style analogue electrical timer",
        knobs: "Metal knobs (2)",
        door: "Double glazed oven door",
        interns: "1 Flat Tray + 1 Grid",
        image: require("../../assets/Focus-Line/Ovens/F526AN.webp")
    }
]
